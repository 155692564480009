import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.css";
import Video from "../../components/video";
import { Alert, Button, ConfigProvider, List } from "antd/lib";
import { antdTheme } from "../../config/theme";
import { BellFilled } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import VideoFilters from "../../components/videoFilters";
import Link from "antd/lib/typography/Link";
import { Video as VideoType } from "../../services/types";
import { getVideos, refreshUser } from "../../services/userFunctions";
import { GetVideosResponse } from "../../services/responses";
import Loader from "../../components/loader";
import { getLoggedUser } from "../../auth/userService";
import UserHeader from "../../components/userHeader";
import locale from "antd/locale/pt_BR";
import { VideoFiltersPayload } from "../../services/payloads";

const MyVideos: React.FC = () => {
  const navigate = useNavigate();
  const [session, setSession] = useState(() => getLoggedUser());
  const [open, setOpen] = useState(false);
  const [videos, setVideos] = useState<VideoType[]>([]);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [searchParams] = useSearchParams();

  const refreshUserCallback = useCallback(async () => {
    if (searchParams.get("purchase") && session) {
      await refreshUser(session.id);

      const updatedSession = getLoggedUser();
      setSession(updatedSession);

      searchParams.delete("purchase");
      navigate(`/videos/me?${searchParams.toString()}`, { replace: true });
    }
  }, [searchParams, session, navigate]);

  useEffect(() => {
    refreshUserCallback();
  }, [refreshUserCallback]);

  const getAllVideos = useCallback(
    async (filters?: VideoFiltersPayload) => {
      setLoadingVideos(true);
      if (session && typeof session !== "string") {
        const getAllVideosResponse = await getVideos({
          userId: session.id,
          ...filters,
        });
        if (!getAllVideosResponse.isError) {
          const res = (getAllVideosResponse as GetVideosResponse).data;
          setVideos(res);
        } else {
          // TODO tratar erro
        }
      }
      setLoadingVideos(false);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    getAllVideos();
  }, [getAllVideos]);

  return (
    <ConfigProvider theme={antdTheme} locale={locale}>
      <UserHeader
        openCartPopoverState={open}
        setOpenCartPopoverState={setOpen}
      />
      <section className={styles.filters}>
        <VideoFilters onFilter={(filters) => getAllVideos(filters)} />
      </section>
      <section className={styles.alertWrapper}>
        {!loadingVideos && (
          <Alert
            type="success"
            showIcon
            icon={<BellFilled />}
            message={
              <>
                {session?.hasPlan
                  ? "Você possui um plano ativo. Os descontos são aplicados no carrinho."
                  : "Assine um plano e ganhe desconto na compra de vídeos!"}{" "}
                <Link className="mobile-link">Ver planos</Link>
              </>
            }
            action={<Button type="primary">Ver planos</Button>}
            onClick={() => navigate("/videos/plans")}
          />
        )}
      </section>
      <section className={styles.main}>
        {loadingVideos ? (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        ) : (
          <List
            className={styles.videoList}
            pagination={{ pageSize: 15 }}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 3,
              lg: 4,
              xl: 4,
              xxl: 5,
            }}
            dataSource={videos}
            renderItem={(video) => (
              <List.Item>
                <Video
                  video={video}
                  onAddToCard={() => navigate(`/video`)}
                  enableOwnerOptions
                />
              </List.Item>
            )}
          />
        )}
      </section>
    </ConfigProvider>
  );
};

export default MyVideos;
