import React, { useCallback, useRef, useState } from "react";
import {
  SearchOutlined,
  CalendarFilled,
  SortAscendingOutlined,
  SortDescendingOutlined,
  OrderedListOutlined,
  FilterFilled,
} from "@ant-design/icons";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import {
  Badge,
  Button,
  Card,
  DatePicker,
  Input,
  Space,
  Table,
  Tag,
} from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";
import dayjs from "dayjs";
import styles from "./styles.module.css";
import isBetween from "dayjs/plugin/isBetween";
import { SortOrder } from "antd/lib/table/interface";
import { ListPurchasesVideosData } from "../../../services/types";
import Loader from "../../loader";
import Highlighter, { HighlighterProps } from "react-highlight-words";
const HighlighterComponent =
  Highlighter as unknown as React.FC<HighlighterProps>;
dayjs.extend(isBetween);

type DataIndex = keyof ListPurchasesVideosData;

type ListPurchasesVideosDataProps = {
  lisVideosPurchases: ListPurchasesVideosData[];
  loading: boolean;
  onRowClick?: (rowContent: ListPurchasesVideosData) => void;
};

const VideoSalesTable: React.FC<ListPurchasesVideosDataProps> = ({
  loading,
  lisVideosPurchases,
  onRowClick,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [datesFilter, setDatesFilter] = useState<null | (dayjs.Dayjs | null)[]>(
    null
  );

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const defaultSorter = (a: string, b: string) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const getSortIcon = (orderType: SortOrder) => {
    switch (orderType) {
      case "ascend":
        return <SortAscendingOutlined />;
      case "descend":
        return <SortDescendingOutlined />;

      default:
        return <OrderedListOutlined />;
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    placeholder: string
  ): TableColumnType<ListPurchasesVideosData> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${placeholder}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>

          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchText("");
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    sortIcon: (props) => (
      <Button
        size="small"
        type="primary"
        icon={getSortIcon(props.sortOrder)}
      ></Button>
    ),
    filterIcon: (filtered: boolean) => (
      <Badge size="small" count={filtered ? 1 : 0}>
        <Button size="small" type="primary" icon={<SearchOutlined />}>
          Buscar
        </Button>
      </Badge>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <HighlighterComponent
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const onRangeChange = (dates: null | (dayjs.Dayjs | null)[]) => {
    if (dates) {
      setDatesFilter([dates[0], dates[1]]);
    } else {
      setDatesFilter(null);
    }
  };

  const getFilteredData = useCallback(() => {
    if (datesFilter !== null) {
      const allData = [...lisVideosPurchases];
      return allData.filter((souceItem) =>
        dayjs(souceItem.purchasedAt).isBetween(
          dayjs(datesFilter[0]).add(-1, "day"),
          dayjs(datesFilter[1]).add(1, "day")
        )
      );
    } else {
      return lisVideosPurchases;
    }
  }, [datesFilter, lisVideosPurchases]);

  const columns: TableColumnsType<ListPurchasesVideosData> = [
    {
      title: "Cliente",
      dataIndex: "customerName",
      key: "customerName",
      ...getColumnSearchProps("customerName", "cliente"),
      sorter: (a, b) => defaultSorter(a.customerName, b.customerName),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Preço",
      dataIndex: "finalPrice",
      key: "finalPrice",
      sorter: (a, b) => a.finalPrice - b.finalPrice,
      sortIcon: (props) => (
        <Button
          size="small"
          type="primary"
          icon={getSortIcon(props.sortOrder)}
        ></Button>
      ),

      sortDirections: ["descend", "ascend"],
      render: (val) =>
        val.toLocaleString("pt-br", { style: "currency", currency: "BRL" }),
    },
    {
      title: "Status da compra",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Pendente", value: "PENDENTE" },
        { text: "Confirmada", value: "CONFIRMADO" },
        { text: "Cancelada", value: "CANCELADO" },
        { text: "Cancelada pelo recebedor", value: "CANCELADO_RECEBEDOR" },
      ],
      sortIcon: (props) => (
        <Button
          size="small"
          type="primary"
          icon={getSortIcon(props.sortOrder)}
        />
      ),
      filterIcon: (filtered) => (
        <Badge size="small" count={filtered ? 1 : 0}>
          <Button size="small" type="primary" icon={<FilterFilled />} />
        </Badge>
      ),
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      render: (value: string) => {
        const statusMap = {
          PENDENTE: { label: "Pendente", color: "orange" },
          CONFIRMADO: { label: "Confirmada", color: "green" },
          CANCELADO: { label: "Cancelada", color: "red" },
          CANCELADO_RECEBEDOR: {
            label: "Cancelada pelo recebedor",
            color: "purple",
          },
        } as const;

        const status = statusMap[value as keyof typeof statusMap] || {
          label: value,
          color: "default",
        };

        return <Tag color={status.color}>{status.label}</Tag>;
      },
    },
    {
      title: "Tipo de compra",
      dataIndex: "planId",
      key: "purchasedFrom",
      filters: [
        { text: "Plano", value: "PLANO" },
        { text: "Avulso", value: "AVULSO" },
      ],
      sortIcon: (props) => (
        <Button
          size="small"
          type="primary"
          icon={getSortIcon(props.sortOrder)}
        />
      ),
      filterIcon: (filtered) => (
        <Badge size="small" count={filtered ? 1 : 0}>
          <Button size="small" type="primary" icon={<FilterFilled />} />
        </Badge>
      ),
      onFilter: (value, record) =>
        (record.planId ? "PLANO" : "AVULSO") === value,
      sorter: (a, b) =>
        (a.planId ? "PLANO" : "AVULSO").localeCompare(
          b.planId ? "PLANO" : "AVULSO"
        ),
      sortDirections: ["descend", "ascend"],
      render: (value, record) => (
        <Tag color={record.planId ? "blue" : "green"}>
          {record.planId ? "Plano" : "Avulso"}
        </Tag>
      ),
    },
    {
      title: "Vídeos comprados",
      dataIndex: "totalVideos",
      key: "totalVideos",
      sorter: (a, b) => a.totalVideos - b.totalVideos,
      sortIcon: (props) => (
        <Button
          size="small"
          type="primary"
          icon={getSortIcon(props.sortOrder)}
        ></Button>
      ),

      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Data da compra",
      dataIndex: "purchasedAt",
      key: "purchasedAt",
      filterDropdown: () => (
        <Card
          actions={[
            <div className={styles.dateActions}>
              <Button
                type="link"
                size="small"
                onClick={() => setDatesFilter(null)}
              >
                Limpar datas
              </Button>
            </div>,
          ]}
          title="Filtrar por data"
        >
          <DatePicker.RangePicker
            onChange={onRangeChange}
            value={datesFilter && [datesFilter[0], datesFilter[1]]}
          />
        </Card>
      ),
      filterIcon: () => (
        <Badge size="small" count={datesFilter !== null ? 1 : 0}>
          <Button size="small" type="primary" icon={<CalendarFilled />} />
        </Badge>
      ),
      render: (value) => dayjs(value).format("DD/MM/YY HH:mm"),
    },
  ];

  return (
    <Table
      className={styles.videosSalesTable}
      loading={{
        spinning: loading,
        indicator: (
          <div>
            <Loader />
          </div>
        ),
      }}
      locale={{
        triggerAsc: "Ordem crescente",
        triggerDesc: "Ordem decrescente",
        cancelSort: "Ordem padrão",
        filterReset: "Limpar",
        filterConfirm: "Filtrar",
      }}
      columns={columns}
      dataSource={getFilteredData()}
      scroll={{ x: 1200 }}
      bordered
      pagination={{ pageSize: 10 }}
    />
  );
};

export default VideoSalesTable;
