import React, { useCallback, useEffect, useState } from "react";
import AppLayout from "../../components/adminLayout";
import styles from "./styles.module.css";
import { Button } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import MainContent from "../../components/mainContent";
import ArenasTable from "../../components/tables/arenasTable";
import { Tabs } from "antd/lib";
import { TbSoccerField } from "react-icons/tb";
import { IoMdFootball } from "react-icons/io";
import { RiMegaphoneFill } from "react-icons/ri";
import FieldsTable from "../../components/tables/fieldsTable";
import AdsList from "../../components/lists/adsList";
import { useNavigate } from "react-router-dom";
import { Ads, Arena, Field } from "../../services/types";
import { getAds, getArenas, getFields } from "../../services/adminFunctions";
import {
  GetAdsResponse,
  GetArenasResponse,
  GetFieldsResponse,
} from "../../services/responses";

const Arenas: React.FC = () => {
  const navigate = useNavigate();
  const [arenas, setArenas] = useState<Arena[]>([]);
  const [fields, setFields] = useState<Field[]>([]);
  const [loadingArenas, setLoadingArenas] = useState(false);
  const [loadingFields, setLoadingFields] = useState(false);
  const [ads, setAds] = useState<Ads[]>([]);
  const [loadingAds, setLoadingAds] = useState(false);

  const getAllAds = useCallback(async () => {
    setLoadingAds(true);
    const getAllAdsResponse = await getAds();
    if (!getAllAdsResponse.isError) {
      const res = (getAllAdsResponse as GetAdsResponse).data;
      setAds(res);
    } else {
      // TODO tratar erro
    }
    setLoadingAds(false);
  }, []);

  const getAllArenas = useCallback(async () => {
    setLoadingArenas(true);
    const getAllArenasResponse = await getArenas();
    if (!getAllArenasResponse.isError) {
      const res = (getAllArenasResponse as GetArenasResponse).data;
      setArenas(res);
    } else {
      // TODO tratar erro
    }
    setLoadingArenas(false);
  }, []);

  const getAllFields = useCallback(async () => {
    setLoadingFields(true);
    const getFieldsResponse = await getFields();
    if (!getFieldsResponse.isError) {
      const res = (getFieldsResponse as GetFieldsResponse).data;
      setFields(res);
    } else {
      // TODO tratar erro
    }
    setLoadingFields(false);
  }, []);

  useEffect(() => {
    getAllArenas();
    getAllFields();
    getAllAds();
  }, [getAllArenas, getAllFields, getAllAds]);

  const [activeContent, setActiveContent] = useState<
    "arenas" | "fields" | "ads"
  >("arenas");

  return (
    <AppLayout title="Arenas">
      <MainContent
        header={
          <Tabs
            className={styles.tabs}
            hideAdd
            onChange={(props) =>
              setActiveContent(props as "arenas" | "fields" | "ads")
            }
            activeKey={activeContent}
            type="card"
            size="large"
            items={[
              {
                label: "Arenas",
                key: "arenas",
                icon: <IoMdFootball />,
              },
              {
                label: "Campos",
                key: "fields",
                icon: <TbSoccerField />,
              },
              {
                label: "Anúncios",
                key: "ads",
                icon: <RiMegaphoneFill />,
              },
            ]}
          />
        }
      >
        {activeContent === "arenas" ? (
          <>
            <div className={styles.header}>
              <Button
                type="primary"
                icon={<PlusCircleFilled />}
                onClick={() => navigate("/admin/arenas/new")}
              >
                Adicionar arena
              </Button>
            </div>
            <ArenasTable loading={loadingArenas} arenas={arenas} />
          </>
        ) : activeContent === "fields" ? (
          <>
            <div className={styles.header}>
              <Button
                type="primary"
                icon={<PlusCircleFilled />}
                onClick={() => navigate("/admin/fields/new")}
              >
                Adicionar campo
              </Button>
            </div>
            <FieldsTable fields={fields} loading={loadingFields} />
          </>
        ) : (
          <>
            <div className={styles.header}>
              <Button
                type="primary"
                icon={<PlusCircleFilled />}
                onClick={() => navigate("/admin/ads/new")}
              >
                Adicionar anúncio
              </Button>
            </div>
            <AdsList ads={ads} loading={loadingAds} />
          </>
        )}
      </MainContent>
    </AppLayout>
  );
};

export default Arenas;
