import React, { useCallback, useRef, useState } from "react";
import {
  SearchOutlined,
  CalendarFilled,
  SortAscendingOutlined,
  SortDescendingOutlined,
  OrderedListOutlined,
  FilterFilled,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import {
  Badge,
  Button,
  Card,
  DatePicker,
  Input,
  Space,
  Table,
  Tag,
} from "antd";
import type { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import dayjs from "dayjs";
import styles from "./styles.module.css";
import isBetween from "dayjs/plugin/isBetween";
import { Plan } from "../../../services/types";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader";
import Highlighter, { HighlighterProps } from "react-highlight-words";
const HighlighterComponent =
  Highlighter as unknown as React.FC<HighlighterProps>;
dayjs.extend(isBetween);

type DataIndex = keyof Plan;

type PlansTableProps = {
  plans: Plan[];
  loading: boolean;
  onRowClick?: (rowContent: Plan) => void;
};
const PlansTable: React.FC<PlansTableProps> = ({
  plans,
  loading,
  onRowClick,
}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [datesFilter, setDatesFilter] = useState<null | (dayjs.Dayjs | null)[]>(
    null
  );

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const defaultSorter = (a: string, b: string) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const getSortIcon = (orderType: SortOrder) => {
    switch (orderType) {
      case "ascend":
        return <SortAscendingOutlined />;
      case "descend":
        return <SortDescendingOutlined />;

      default:
        return <OrderedListOutlined />;
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    placeholder: string
  ): TableColumnType<Plan> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${placeholder}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>

          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchText("");
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    sortIcon: (props) => (
      <Button
        size="small"
        type="primary"
        icon={getSortIcon(props.sortOrder)}
      ></Button>
    ),
    filterIcon: (filtered: boolean) => (
      <Badge size="small" count={filtered ? 1 : 0}>
        <Button size="small" type="primary" icon={<SearchOutlined />}>
          Buscar
        </Button>
      </Badge>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <HighlighterComponent
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const onRangeChange = (
    dates: null | (dayjs.Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setDatesFilter([dates[0], dates[1]]);
    } else {
      setDatesFilter(null);
    }
  };

  const getFilteredData = useCallback(() => {
    if (datesFilter !== null) {
      const allData = [...plans];
      return allData.filter((souceItem) =>
        dayjs(souceItem.createdAt).isBetween(
          dayjs(datesFilter[0]).add(-1, "day"),
          dayjs(datesFilter[1]).add(1, "day")
        )
      );
    } else {
      return plans;
    }
  }, [datesFilter, plans]);

  const columns: TableColumnsType<Plan> = [
    {
      title: "Nome do Plano",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", "plano"),
      sorter: (a, b) => defaultSorter(a.name, b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Preço",
      dataIndex: "price",
      key: "price",
      sortIcon: (props) => (
        <Button
          size="small"
          type="primary"
          icon={getSortIcon(props.sortOrder)}
        ></Button>
      ),
      sorter: (a, b) => a.price - b.price,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Tipo de cobrança",
      dataIndex: "frequency",
      key: "frequency",
      sortIcon: (props) => (
        <Button
          size="small"
          type="primary"
          icon={getSortIcon(props.sortOrder)}
        ></Button>
      ),
      filterIcon: (filtered) => (
        <Badge size="small" count={filtered ? 1 : 0}>
          <Button size="small" type="primary" icon={<FilterFilled />} />
        </Badge>
      ),
      filters: [
        { text: "Anual", value: "ANUAL" },
        { text: "Mensal", value: "MENSAL" },
      ],
      onFilter: (value, record) => record.frequency.startsWith(value as string),
      sorter: (a, b) => defaultSorter(a.frequency, b.frequency),
      sortDirections: ["descend", "ascend"],
      render: (value) => (
        <Tag color={value === "MENSAL" ? "green" : "blue"}>
          {value === "MENSAL" ? "Mensal" : "Anual"}
        </Tag>
      ),
    },
    {
      title: "Status do Plano",
      dataIndex: "isActive",
      key: "isActive",
      filters: [
        { text: "Ativo", value: "Active" },
        { text: "Inativo", value: "Inactive" },
      ],
      sortIcon: (props) => (
        <Button
          size="small"
          type="primary"
          icon={getSortIcon(props.sortOrder)}
        ></Button>
      ),
      filterIcon: (filtered) => (
        <Badge size="small" count={filtered ? 1 : 0}>
          <Button size="small" type="primary" icon={<FilterFilled />} />
        </Badge>
      ),
      sortDirections: ["descend", "ascend"],
      render: (value) => (
        <Tag color={value ? "green" : "default"}>
          {value ? "Ativo" : "Inativo"}
        </Tag>
      ),
    },
    {
      title: "Data de criação",
      dataIndex: "createdAt",
      key: "createdAt",
      filterDropdown: () => (
        <Card
          actions={[
            <div className={styles.dateActions}>
              <Button
                type="link"
                size="small"
                onClick={() => setDatesFilter(null)}
              >
                Limpar datas
              </Button>
            </div>,
          ]}
          title="Filtrar por data"
        >
          <DatePicker.RangePicker
            onChange={onRangeChange}
            value={datesFilter && [datesFilter[0], datesFilter[1]]}
          />
        </Card>
      ),
      filterIcon: () => (
        <Badge size="small" count={datesFilter !== null ? 1 : 0}>
          <Button size="small" type="primary" icon={<CalendarFilled />} />
        </Badge>
      ),
      render: (value) => dayjs(value).format("DD/MM/YY"),
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (value, record) => (
        <>
          <Button
            type="primary"
            icon={<EditFilled />}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/admin/plans/edit/${record.id}`);
            }}
            style={{ marginRight: 8 }}
          />
          <Button
            title="Ative/Inative seu plano editando o registro"
            type="primary"
            danger
            icon={<DeleteFilled />}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/admin/plans/edit/${record.id}`);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <Table
      className={styles.plansTable}
      onRow={(r) => ({
        onClick: () =>
          onRowClick ? onRowClick(r) : navigate(`/admin/plans/edit/${r.id}`),
      })}
      loading={{
        spinning: loading,
        indicator: (
          <div>
            <Loader />
          </div>
        ),
      }}
      locale={{
        triggerAsc: "Ordem crescente",
        triggerDesc: "Ordem decrescente",
        cancelSort: "Ordem padrão",
        filterReset: "Limpar",
        filterConfirm: "Filtrar",
      }}
      columns={columns}
      dataSource={getFilteredData()}
      scroll={{ x: 1000 }}
      bordered
      pagination={{ pageSize: 10 }}
    />
  );
};

export default PlansTable;
