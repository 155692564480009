import React, { useState } from "react";
import { Button, Drawer } from "antd";
import styles from "./styles.module.css";
import {
  ArrowLeftOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import VideoCartItem from "../videoCartItem";
import { Alert, Space } from "antd/lib";
import { clearCart, getCartItems, getLoggedUser } from "../../auth/userService";
import { createPurchase } from "../../services/userFunctions";
import { useNavigate } from "react-router-dom";
import {
  CreatePurchaseResponse,
  LoginResponsePayload,
  UserPlanResponse,
} from "../../services/responses";
import { PurchaseData, Video } from "../../services/types";
import { encrypt } from "../../services/crypto";
import { Weekday } from "../../services/payloads";

type ShoppingCartProps = {
  onUpdate?: () => void;
};

const ShoppingCart: React.FC<ShoppingCartProps> = ({ onUpdate }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const session = getLoggedUser();
  const userIsLogged = session && typeof session !== "string";
  const [creatingPurchase, setCreatingPurchase] = useState(false);
  const [cartItems, setCartItems] = useState(getCartItems());

  const showDrawer = () => {
    const freshCartItems = getCartItems();

    if (session && typeof session !== "string") {
      setCartItems(calculateVideoPrices(freshCartItems, session));
    } else {
      setCartItems(freshCartItems);
    }
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    onUpdate && onUpdate();
  };

  const getWeekday = (date: Date): Weekday => {
    const weekdayMap: { [key: number]: Weekday } = {
      0: Weekday.DOMINGO,
      1: Weekday["SEGUNDA-FEIRA"],
      2: Weekday["TERÇA-FEIRA"],
      3: Weekday["QUARTA-FEIRA"],
      4: Weekday["QUINTA-FEIRA"],
      5: Weekday["SEXTA-FEIRA"],
      6: Weekday.SABADO,
    };

    return weekdayMap[date.getDay()];
  };

  const calculateVideoPrices = (
    videos: Video[],
    userSession: LoginResponsePayload
  ): Video[] => {
    console.log("Calculate", videos, userSession);
    const { plans, actualVideoCount } = userSession;
    let videoCount = actualVideoCount;

    // Helper function to check if a video is within a plan's unlimited rules
    const isWithinUnlimitedPlanRules = (
      video: Video,
      plan: UserPlanResponse
    ): boolean => {
      const videoDate = new Date(video.recordedAt);
      const isSameWeekday = getWeekday(videoDate) === plan.bookingWeekday;
      const isSameField = plan.fieldId === video.fieldId;
      const bookingStartTime = new Date(plan.bookingStartsAt).setFullYear(
        1970,
        0,
        1
      );
      const bookingEndTime = new Date(plan.bookingEndsAt).setFullYear(
        1970,
        0,
        1
      );
      const recordedTime = videoDate.setFullYear(1970, 0, 1);

      return (
        isSameWeekday &&
        isSameField &&
        recordedTime >= bookingStartTime &&
        recordedTime <= bookingEndTime
      );
    };

    // Process videos
    return videos.map((video) => {
      let price = video.videoPrice;
      let finalPriceMotive: "DEFAULT" | "UNLIMITED" | "NORMAL" = "DEFAULT";

      // 1. Check for default plan discount
      const defaultPlan = plans.find((plan) => plan.isDefault);
      if (defaultPlan) {
        price = price - (price * defaultPlan.discount) / 100;
      }

      // 2. Check NORMAL plans (videosQuantity > 0)
      const normalPlan = plans.find(
        (plan) => plan.videosQuantity > 0 && !plan.isDefault
      );
      if (normalPlan) {
        const remainingVideos =
          Number(normalPlan.videosQuantity) - Number(videoCount);
        if (remainingVideos > 0) {
          videoCount += 1;
          price = 0; // Free video under the plan
          finalPriceMotive = "NORMAL";
        }
      }

      // 3. Check UNLIMITED plans (videosQuantity = 0)
      const unlimitedPlans = plans.filter(
        (plan) => plan.videosQuantity === 0 && !plan.isDefault
      );
      const isFreeInUnlimitedPlan = unlimitedPlans.some((plan) =>
        isWithinUnlimitedPlanRules(video, plan)
      );
      if (isFreeInUnlimitedPlan) {
        price = 0; // Free video under the unlimited plan
        finalPriceMotive = "UNLIMITED";
      }

      return { ...video, videoFinalPrice: price, finalPriceMotive }; // Update the video's price
    });
  };

  const initializePurchase = async () => {
    setCreatingPurchase(true);
    if (session && typeof session !== "string") {
      const cartItems: Video[] = getCartItems();

      const updatedCartItems = calculateVideoPrices(cartItems, session);
      setCartItems(updatedCartItems);

      const totalPrice = updatedCartItems.reduce(
        (total, video) => total + parseFloat(String(video.videoPrice)),
        0
      );

      const totalFinalPrice = updatedCartItems.reduce(
        (total, video) => total + parseFloat(String(video.videoFinalPrice)),
        0
      );

      const totalDiscount =
        totalPrice - totalFinalPrice > 0 ? totalPrice - totalFinalPrice : 0;

      const payload = {
        userId: session.id,
        price: parseFloat(totalPrice.toFixed(2)),
        discount: totalDiscount,
        finalPrice: parseFloat(totalFinalPrice.toFixed(2)),
        items: getCartItems().length,
        videos: [...getCartItems().map((item) => item.id)],
      };

      const createPurchaseResponse = await createPurchase(payload);

      if (!createPurchaseResponse.isError) {
        const res = createPurchaseResponse as CreatePurchaseResponse;
        if (res.status === "success") {
          clearCart();
          navigate(`/videos/me?purchase=FINISHED`);
        } else {
          const payloadWithPurchaseData: PurchaseData = {
            ...payload,
            cartItems: updatedCartItems,
            payer: session,
            hasDocument: session.hasDocument,
            externalReference: res.externalReference,
          };
          clearCart();
          navigate(`/purchase/${await encrypt(payloadWithPurchaseData)}`);
        }
      } else {
        alert(
          "Houve um erro na compra, recarregando a página. Se o problema persistir nos avise."
        );
        setTimeout(() => {
          navigate(`/videos?refreshUser=TRUE`);
        }, 4000);
      }
    }
    setCreatingPurchase(false);
  };

  return (
    <>
      <Button
        onClick={showDrawer}
        className={styles.filtersWrapper}
        icon={<ShoppingCartOutlined />}
        type="text"
      ></Button>
      <Drawer
        placement="right"
        title="Carrinho"
        onClose={onClose}
        open={open}
        footer={
          userIsLogged && (
            <div className={styles.actions}>
              <div className="sumary">
                Total:{" "}
                <strong>
                  R${" "}
                  {cartItems
                    .reduce(
                      (total, video) =>
                        total + parseFloat(String(video.videoPrice)),
                      0
                    )
                    .toFixed(2)}
                </strong>
              </div>
              <div className="sumary">
                Descontos:{" "}
                <strong>
                  R$ -{" "}
                  {(
                    cartItems.reduce(
                      (sum, video) =>
                        sum + parseFloat(String(video.videoPrice)),
                      0
                    ) -
                    cartItems.reduce(
                      (sum, video) =>
                        sum + parseFloat(String(video.videoFinalPrice)),
                      0
                    )
                  ).toFixed(2)}
                </strong>
              </div>
              <div className="sumary">
                Valor a pagar:{" "}
                <strong>
                  R${" "}
                  {cartItems
                    .reduce(
                      (total, video) =>
                        total + parseFloat(String(video.videoFinalPrice)),
                      0
                    )
                    .toFixed(2)}
                </strong>
              </div>
              <div className="buttons">
                <Button
                  size="large"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => onClose()}
                >
                  Voltar
                </Button>
                <Button
                  size="large"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={() => initializePurchase()}
                  loading={creatingPurchase}
                >
                  Finalizar compra
                </Button>
              </div>
            </div>
          )
        }
      >
        {cartItems.length > 0 ? (
          <Space direction="vertical" style={{ width: "100%" }}>
            {cartItems.map((video) => (
              <VideoCartItem
                video={video}
                key={video.id}
                removeCallback={() => {
                  const freshCartItems = getCartItems();

                  if (session && typeof session !== "string") {
                    setCartItems(calculateVideoPrices(freshCartItems, session));
                  } else {
                    setCartItems(freshCartItems);
                  }
                }}
              />
            ))}
          </Space>
        ) : (
          <Alert showIcon type="warning" message="Nenhum vídeo adicionado" />
        )}
      </Drawer>
    </>
  );
};

export default ShoppingCart;
