import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { decryptPurchaseData } from "../../services/crypto";
import { PurchaseData } from "../../services/types";
import PurchaseSumary from "../../components/purchaseSumary";
import styles from "./styles.module.css";
import { finishPurchase } from "../../services/userFunctions";
import { FinishPurchaseResponse } from "../../services/responses";
import useMessage from "antd/es/message/useMessage";
import { clearCart } from "../../auth/userService";
import { Button } from "antd";
import { Form, Input, Select, Typography } from "antd/lib";
import { FinishPurchasePayload } from "../../services/payloads";
import { CopyOutlined } from "@ant-design/icons";
import { MaskedInput } from "antd-mask-input";

const { Option } = Select;
const { Text } = Typography;

const Purchase = () => {
  let { purchaseData } = useParams();
  const navigate = useNavigate();
  const data: PurchaseData = decryptPurchaseData(
    String(purchaseData)
  ) as PurchaseData;
  const [form] = Form.useForm();
  const [billingType, setBillingType] = useState<string | undefined>();
  const [pixData, setPixData] = useState<{
    encodedImage: string;
    payload: string;
  } | null>(null);

  const [finishPurchaseData, setFinishPurchaseData] = useState<
    FinishPurchaseResponse | undefined
  >(undefined);

  const [message, contextHolder] = useMessage();

  const onFinish = async (values: any) => {
    const remoteIp = await fetch("https://api64.ipify.org?format=json")
      .then((res) => res.json())
      .then((data) => data.ip);

    const payload: FinishPurchasePayload = {
      userId: data.userId,
      externalReference: data.externalReference,
      billingType: values.billingType,
      remoteIp,
      document: values.cpf || undefined,
    };

    if (values.billingType === "CREDIT_CARD") {
      payload.creditCard = {
        holderName: values.holderName,
        number: values.number,
        expiryMonth: values.expiryMonth,
        expiryYear: values.expiryYear,
        ccv: values.ccv,
      };
      payload.creditCardHolderInfo = {
        name: values.holderName,
        email: values.email,
        cpfCnpj: values.cpf,
        postalCode: values.postalCode,
        addressNumber: values.addressNumber,
        addressComplement: values.addressComplement,
        mobilePhone: values.mobilePhone,
      };
    }

    try {
      const purchaseResponse = await finishPurchase(payload);

      if (!purchaseResponse.isError) {
        clearCart();

        if (
          "status" in purchaseResponse &&
          purchaseResponse.status === "pending"
        ) {
          setPixData({
            encodedImage: purchaseResponse.qrCode,
            payload: purchaseResponse.pixCode,
          });
        }

        if (
          "status" in purchaseResponse &&
          purchaseResponse.status === "success"
        ) {
          setFinishPurchaseData(purchaseResponse as FinishPurchaseResponse);
          message.success("Compra realizada com sucesso!");
          setTimeout(() => {
            navigate("/videos/me?purchase=FINISHED");
          }, 2000);
        }
      } else {
        message.error("Erro ao processar pagamento. Tente novamente.", 10);
      }
    } catch (err) {
      message.error("Erro ao processar pagamento. Tente novamente.", 10);
    }
  };

  return (
    <>
      <div className={styles.logo}>
        <Link to={"/"}>
          <img alt="logo" src="/logo-full.png" height={"100%"} />
        </Link>
      </div>

      {contextHolder}
      {finishPurchaseData ? (
        <div className={styles.statusWrapper}>
          <Button
            size="large"
            onClick={() => navigate("/videos/me?purchase=FINISHED")}
            type="primary"
          >
            Ver meus vídeos
          </Button>
        </div>
      ) : pixData ? (
        <div className={styles.pixWrapper}>
          <Text strong>Escaneie o QR Code para pagar:</Text>
          <img
            src={`data:image/png;base64,${pixData.encodedImage}`}
            alt="PIX QR Code"
          />
          <Text strong>Ou copie e cole o código abaixo:</Text>
          <Input
            value={pixData.payload}
            readOnly
            style={{ width: 300 }}
            addonAfter={
              <CopyOutlined
                onClick={() => navigator.clipboard.writeText(pixData.payload)}
              />
            }
          />
          <Button
            type="primary"
            onClick={() => navigate("/videos/me?purchase=FINISHED")}
          >
            Já efetuei o pagamento
          </Button>
        </div>
      ) : (
        <div className={styles.purchaseWrapper}>
          <Form
            form={form}
            className={styles.form}
            name="complex-form"
            onFinish={onFinish}
            labelCol={{ span: 32 }}
            wrapperCol={{ span: 32 }}
            layout="vertical"
          >
            <div className={styles.creditCardFields}>
              <Form.Item
                name="cpf"
                label="CPF"
                style={{ maxWidth: 140 }}
                hidden={data.hasDocument}
                rules={[
                  { required: !data.hasDocument, message: "CPF é obrigatório" },
                  {
                    pattern: new RegExp("^\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2}$"),
                    message: "Número de CPF inválido!",
                  },
                ]}
              >
                <MaskedInput
                  mask={"000.000.000-00"}
                  placeholder="000.000.000-00"
                />
              </Form.Item>
              <Form.Item
                name="billingType"
                label="Forma de Pagamento"
                style={{ maxWidth: 190 }}
                rules={[
                  { required: true, message: "Escolha a forma de pagamento" },
                ]}
              >
                <Select onChange={setBillingType}>
                  <Option value="CREDIT_CARD">Cartão de Crédito</Option>
                  <Option value="PIX">PIX</Option>
                </Select>
              </Form.Item>
            </div>
            {billingType === "CREDIT_CARD" && (
              <>
                <div className={styles.creditCardFields}>
                  <Form.Item
                    name="holderName"
                    label="Nome do Titular"
                    rules={[{ required: true, message: "Preencha o nome" }]}
                  >
                    <Input placeholder="Nome impresso no cartão" />
                  </Form.Item>

                  <Form.Item
                    name="number"
                    label="Número do Cartão"
                    rules={[
                      {
                        required: true,
                        message: "Preencha o número do cartão",
                      },
                    ]}
                  >
                    <MaskedInput
                      mask={"0000 0000 0000 0000"}
                      placeholder="Número do cartão"
                    />
                  </Form.Item>
                </div>

                <div className={styles.expiryFields}>
                  <Form.Item
                    name="expiryMonth"
                    label="Mês"
                    rules={[
                      {
                        required: true,
                        message: "Preencha o mês",
                        min: 2,
                        max: 2,
                      },
                    ]}
                  >
                    <MaskedInput mask={"00"} placeholder="MM" />
                  </Form.Item>

                  <Form.Item
                    name="expiryYear"
                    label="Ano"
                    rules={[
                      {
                        required: true,
                        message: "Preencha o ano",
                        min: 4,
                        max: 4,
                      },
                    ]}
                  >
                    <MaskedInput mask={"0000"} placeholder="YYYY" />
                  </Form.Item>

                  <Form.Item
                    name="ccv"
                    label="CCV"
                    rules={[
                      {
                        required: true,
                        message: "Preencha o CCV",
                        min: 3,
                        max: 3,
                      },
                    ]}
                  >
                    <MaskedInput mask={"000"} placeholder="CCV" />
                  </Form.Item>
                </div>

                <div className={styles.creditCardFields}>
                  <Form.Item
                    name="postalCode"
                    label="CEP"
                    rules={[
                      { required: true, message: "Preencha o CEP" },
                      {
                        pattern: new RegExp("^\\d{5}-\\d{3}$"),
                        message: "CEP inválido!",
                      },
                    ]}
                  >
                    <MaskedInput mask={"00000-000"} placeholder="89160-000" />
                  </Form.Item>

                  <Form.Item
                    name="addressNumber"
                    label="Número do Endereço"
                    rules={[{ required: true, message: "Preencha o número" }]}
                  >
                    <Input placeholder="Número do endereço" />
                  </Form.Item>

                  <Form.Item name="addressComplement" label="Complemento">
                    <Input placeholder="Apartamento, bloco, etc." />
                  </Form.Item>
                </div>

                <div className={styles.creditCardFields}>
                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      { required: true, message: "Preencha o e-mail" },
                      {
                        pattern: new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                        message: "E-mail inválido!",
                      },
                    ]}
                  >
                    <Input placeholder="E-mail do titular" />
                  </Form.Item>

                  <Form.Item
                    name="mobilePhone"
                    label="Celular"
                    style={{ maxWidth: 150 }}
                    rules={[
                      {
                        required: true,
                        message: "Número de celular obrigatório",
                      },
                      {
                        pattern: new RegExp(/^\d{2} \d \d{4}-\d{4}$/),
                        message: "Número de celular inválido!",
                      },
                    ]}
                  >
                    <MaskedInput
                      mask={"00 0 0000-0000"}
                      placeholder="99 9 9999-9999"
                    />
                  </Form.Item>
                </div>
              </>
            )}

            <Button type="primary" htmlType="submit">
              Finalizar Compra
            </Button>
          </Form>
          <PurchaseSumary purchase={data} />
        </div>
      )}
    </>
  );
};
export default Purchase;
