import { Address, Arena, User } from "./types";

export type GetAuthUserInfoPayload = {
  token: string;
};

export interface CreateArenaPayload extends Arena {
  arenaId?: string;
  userId: string;
  contactName: string;
  contactPhone: string;
}

export interface UpdateUserPayload extends User {
  adminId: string;
  userId?: string;
  phone: string;
  password: string;
  address: Address;
}

export interface SaveProfilePayload extends User {
  userId: string;
  phone: string;
  password: string;
  address: Address;
  favoriteArenaId?: string;
}

export type CreateFieldPayload = {
  userId: string;
  fieldId?: string;
  arenaId: string;
  description: string;
  monday: "MINUTO_00" | "MINUTO_30";
  tuesday: "MINUTO_00" | "MINUTO_30";
  wednesday: "MINUTO_00" | "MINUTO_30";
  thursday: "MINUTO_00" | "MINUTO_30";
  friday: "MINUTO_00" | "MINUTO_30";
  saturday: "MINUTO_00" | "MINUTO_30";
  sunday: "MINUTO_00" | "MINUTO_30";
  toleranceWeekday: number;
  toleranceWeekend: number;
};

export type UploadImagePayload = {
  image: string;
};

export type RegisterUserPayload = {
  name: string;
  email: string;
  password: string;
};

export type LoginPayload = {
  email: string;
  password: string;
};

export type CreateOrUpdatePlanPayload = {
  id?: string;
  userId: string;
  name: string;
  price: string;
  videosQuantity: string;
  frequency: "MENSAL" | "ANUAL";
  description: string;
  priceDescription: string;
  isActive: boolean;
};

export type VideoFiltersPayload = {
  arenaId?: string;
  fieldId?: string;
  startingDate?: string;
  endingDate?: string;
  city?: string;
  state?: string;
  userId?: string;
};

export type CreatePurchasePayload = {
  userId: string;
  price: number;
  discount: number;
  finalPrice: number;
  items: number;
  videos: string[];
};

export type GetUserVideoPayload = {
  userId: string;
  videoId: string;
  purchaseId: string;
};

export type UpdateAdsPayload = {
  userId: string;
  adsId: string;
  name: string;
  img: string;
};

export type CreditCard = {
  holderName: string;
  number: string;
  expiryMonth: string;
  expiryYear: string;
  ccv: string;
};

export type CreditCardHolderInfo = {
  name: string;
  email: string;
  cpfCnpj: string;
  postalCode: string;
  addressNumber: string;
  addressComplement: string;
  mobilePhone: string;
};

export interface FinishPurchasePayload {
  userId: string;
  externalReference?: string;
  billingType: "CREDIT_CARD" | "PIX";
  remoteIp: string;
  creditCard?: CreditCard;
  creditCardHolderInfo?: CreditCardHolderInfo;
  document?: string;
}

export enum Weekday {
  "SEGUNDA-FEIRA" = "SEGUNDA-FEIRA",
  "TERÇA-FEIRA" = "TERÇA-FEIRA",
  "QUARTA-FEIRA" = "QUARTA-FEIRA",
  "QUINTA-FEIRA" = "QUINTA-FEIRA",
  "SEXTA-FEIRA" = "SEXTA-FEIRA",
  SABADO = "SABADO",
  DOMINGO = "DOMINGO",
}
export interface FinishPlanPurchasePayload {
  userId: string;
  document?: string;
  planId: string;
  price: number;
  bookingStartsAt: Date;
  bookingEndsAt: Date;
  bookingWeekday: Weekday;
  fieldId: string;
  remoteIp: string;
  creditCard: CreditCard;
  creditCardHolderInfo: CreditCardHolderInfo;
}

export type PurchaseVideosFiltersPayload = {
  adminId: string;
};

export type PurchasePlansFiltersPayload = {
  adminId: string;
};

export interface CancelPlanPayload {
  userId: string;
  planId: string;
}
