import dayjs from "dayjs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { decryptPlanPurchaseData } from "../../services/crypto";
import { Field, PlanPurchaseData } from "../../services/types";
import styles from "./styles.module.css";
import { finishPlanPurchase } from "../../services/userFunctions";
import useMessage from "antd/es/message/useMessage";
import { Button, Form, Select } from "antd";
import { FinishPlanPurchasePayload, Weekday } from "../../services/payloads";
import PlanPurchaseSummary from "../../components/planPurchaseSumary";
import { TbSoccerField } from "react-icons/tb";
import { getFields } from "../../services/adminFunctions";
import { GetFieldsResponse } from "../../services/responses";
import { MaskedInput } from "antd-mask-input";
import { Input, Typography } from "antd/lib";

const { Option } = Select;

const PlanPurchase = () => {
  let { purchaseData } = useParams();
  const navigate = useNavigate();
  const [fields, setFields] = useState<Field[]>([]);
  const [form] = Form.useForm();
  const [fieldId, setFieldId] = useState<string | undefined>(undefined);
  const [message] = useMessage();
  const [hour, setHour] = useState<number | undefined>(undefined);
  const [minute, setMinute] = useState<string | undefined>(undefined);
  const [bookingWeekday, setBookingWeekday] = useState<Weekday | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);

  const data = useMemo(() => {
    return decryptPlanPurchaseData(String(purchaseData)) as PlanPurchaseData;
  }, [purchaseData]);

  const onFieldChange = useCallback((value: string) => setFieldId(value), []);
  const onBookingWeekdayChange = useCallback(
    (value: Weekday) => setBookingWeekday(value),
    []
  );
  const onHourChange = useCallback((value: number) => setHour(value), []);
  const onMinuteChange = useCallback((value: string) => setMinute(value), []);

  const getAllFields = useCallback(async () => {
    const getFieldsResponse = await getFields();
    if (!getFieldsResponse.isError) {
      const res = (getFieldsResponse as GetFieldsResponse).data;
      setFields(res);
    }
  }, []);

  useEffect(() => {
    getAllFields();
  }, [getAllFields]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const purchase: PlanPurchaseData = data;

    if (!hour || !minute || !bookingWeekday || !fieldId) {
      message.error("Preencha todas as informações de agendamento.");
      return;
    }

    const bookingStartsAt = dayjs(
      `1970-01-01T${String(hour).padStart(2, "0")}:${minute}:00`
    );
    const bookingEndsAt = bookingStartsAt.add(1, "hour").toDate();

    const remoteIp = await fetch("https://api64.ipify.org?format=json")
      .then((res) => res.json())
      .then((data) => data.ip);

    const planPurchasePayload: FinishPlanPurchasePayload = {
      userId: purchase.userId,
      planId: purchase.planId,
      price: purchase.price,
      bookingStartsAt: bookingStartsAt.toDate(),
      bookingEndsAt,
      bookingWeekday,
      fieldId,
      remoteIp,
      document: values.cpf || undefined,
      creditCard: {
        holderName: values.holderName,
        number: values.number,
        expiryMonth: values.expiryMonth,
        expiryYear: values.expiryYear,
        ccv: values.ccv,
      },
      creditCardHolderInfo: {
        name: values.holderName,
        email: values.email,
        cpfCnpj: values.cpf,
        postalCode: values.postalCode,
        addressNumber: values.addressNumber,
        addressComplement: values.addressComplement,
        mobilePhone: values.mobilePhone,
      },
    };

    const purchaseResponse = await finishPlanPurchase(planPurchasePayload);
    if (!purchaseResponse.isError) {
      setLoading(false);
      message.success(
        "Compra de plano efetuada, dentro de alguns minutos seu plano estará ativo!",
        10
      );
      navigate("/videos?refreshUser=TRUE");
    } else {
      setLoading(false);
      message.error(
        "Ocorreu um erro ao processar sua compra. Verifique sua conexão ou tente novamente",
        10
      );
    }
  };

  return (
    <>
      <div className={styles.logo}>
        <Link to={"/"}>
          <img alt="logo" src="/logo-full.png" height={"100%"} />
        </Link>
      </div>

      <div className={styles.purchaseWrapper}>
        <Form
          form={form}
          className={styles.form}
          name="complex-form"
          onFinish={onFinish}
          labelCol={{ span: 32 }}
          wrapperCol={{ span: 32 }}
          layout="vertical"
        >
          <div className={styles.creditCardFields}>
            <Form.Item
              name="cpf"
              label="CPF"
              hidden={data.hasDocument}
              rules={[
                { required: !data.hasDocument, message: "CPF é obrigatório" },
                {
                  pattern: new RegExp("^\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2}$"),
                  message: "Número de CPF inválido!",
                },
              ]}
            >
              <MaskedInput
                mask={"000.000.000-00"}
                placeholder="000.000.000-00"
              />
            </Form.Item>
            <Form.Item
              style={{ width: 300 }}
              label={
                <>
                  <TbSoccerField style={{ marginRight: 8 }} /> Buscar por campo
                </>
              }
            >
              <Select
                placeholder="Nome do campo"
                showSearch
                options={fields.map((f) => ({
                  ...f,
                  label: f.arenaName + " - " + f.description,
                  value: f.id,
                }))}
                optionFilterProp="label"
                onChange={onFieldChange}
                allowClear
              />
            </Form.Item>
          </div>
          <div className={styles.creditCardFields}>
            <Form.Item
              name="bookingWeekday"
              label="Dia da Semana"
              rules={[{ required: true, message: "Campo obrigatório" }]}
            >
              <Select
                placeholder="Dia da Semana"
                onChange={onBookingWeekdayChange}
              >
                {Object.values(Weekday).map((day) => (
                  <Option key={day} value={day}>
                    {day}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="bookingStartsAt"
              label="Hora de início do seu horário"
              rules={[{ required: true, message: "Campo obrigatório" }]}
            >
              <Select
                placeholder="Hora de início do seu horário"
                onChange={onHourChange}
                style={{ width: 200 }}
              >
                {Array.from({ length: 24 }, (_, i) => i + 1).map((hour) => (
                  <Option key={hour} value={hour}>
                    {hour} horas
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="bookingEndsAt"
              label="Minutos de início do seu horário"
              rules={[{ required: true, message: "Campo obrigatório" }]}
            >
              <Select
                placeholder="Minutos de início do seu horário"
                onChange={onMinuteChange}
                style={{ width: 200 }}
              >
                <Option value="00">00</Option>
                <Option value="30">30</Option>
              </Select>
            </Form.Item>
          </div>
          <Form.Item>
            <Typography>Dados do seu cartão</Typography>
          </Form.Item>
          <div className={styles.creditCardFields}>
            <Form.Item
              name="holderName"
              label="Nome do Titular"
              rules={[{ required: true, message: "Preencha o nome" }]}
            >
              <Input placeholder="Nome impresso no cartão" />
            </Form.Item>

            <Form.Item
              name="number"
              label="Número do Cartão"
              rules={[
                {
                  required: true,
                  message: "Preencha o número do cartão",
                },
              ]}
            >
              <MaskedInput
                mask={"0000 0000 0000 0000"}
                placeholder="Número do cartão"
              />
            </Form.Item>
          </div>

          <div className={styles.expiryFields}>
            <Form.Item
              name="expiryMonth"
              label="Mês"
              rules={[
                {
                  required: true,
                  message: "Preencha o mês",
                  min: 2,
                  max: 2,
                },
              ]}
            >
              <MaskedInput mask={"00"} placeholder="MM" />
            </Form.Item>

            <Form.Item
              name="expiryYear"
              label="Ano"
              rules={[
                {
                  required: true,
                  message: "Preencha o ano",
                  min: 4,
                  max: 4,
                },
              ]}
            >
              <MaskedInput mask={"0000"} placeholder="YYYY" />
            </Form.Item>

            <Form.Item
              name="ccv"
              label="CCV"
              rules={[
                {
                  required: true,
                  message: "Preencha o CCV",
                  min: 3,
                  max: 3,
                },
              ]}
            >
              <MaskedInput mask={"000"} placeholder="CCV" />
            </Form.Item>
          </div>

          <div className={styles.creditCardFields}>
            <Form.Item
              name="postalCode"
              label="CEP"
              rules={[
                { required: true, message: "Preencha o CEP" },
                {
                  pattern: new RegExp("^\\d{5}-\\d{3}$"),
                  message: "CEP inválido!",
                },
              ]}
            >
              <MaskedInput mask={"00000-000"} placeholder="89160-000" />
            </Form.Item>

            <Form.Item
              name="addressNumber"
              label="Número do Endereço"
              rules={[{ required: true, message: "Preencha o número" }]}
            >
              <Input placeholder="Número do endereço" />
            </Form.Item>

            <Form.Item name="addressComplement" label="Complemento">
              <Input placeholder="Apartamento, bloco, etc." />
            </Form.Item>
          </div>

          <div className={styles.creditCardFields}>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                { required: true, message: "Preencha o e-mail" },
                {
                  pattern: new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                  message: "E-mail inválido!",
                },
              ]}
            >
              <Input placeholder="E-mail do titular" />
            </Form.Item>

            <Form.Item
              name="mobilePhone"
              label="Celular"
              style={{ maxWidth: 150 }}
              rules={[
                {
                  required: true,
                  message: "Número de celular obrigatório",
                },
                {
                  pattern: new RegExp(/^\d{2} \d \d{4}-\d{4}$/),
                  message: "Número de celular inválido!",
                },
              ]}
            >
              <MaskedInput
                mask={"00 0 0000-0000"}
                placeholder="99 9 9999-9999"
              />
            </Form.Item>
          </div>

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            Finalizar Compra
          </Button>
        </Form>
        <PlanPurchaseSummary purchase={data} />
      </div>
    </>
  );
};
export default PlanPurchase;
