import React from "react";
import { Plan, PlanPurchaseData } from "../../services/types";
import styles from "./styles.module.css";
import { Card } from "antd";
import { Button, Popconfirm } from "antd/lib";
import { useNavigate } from "react-router-dom";
import { encrypt } from "../../services/crypto";
import { getLoggedUser } from "../../auth/userService";
import { cancelPlan } from "../../services/userFunctions";
type PlanCardProps = {
  plan: Plan;
  userPlan?: boolean;
};

const PlanCard: React.FC<PlanCardProps> = ({ plan, userPlan }) => {
  const navigate = useNavigate();
  const session = getLoggedUser();

  const handlePlanButton = async () => {
    if (userPlan && session && typeof session !== "string") {
      await cancelPlan({ planId: plan.id, userId: session.id });
      navigate(`/videos?refreshUser=true`);
    } else {
      await initializePurchase();
    }
  };

  const initializePurchase = async () => {
    if (session && typeof session !== "string") {
      const purchasePayload: PlanPurchaseData = {
        userId: session.id,
        planId: plan.id,
        planName: plan.name,
        videosQuantity: plan.videosQuantity,
        price: parseFloat(plan.price.toFixed(2)),
        payer: session,
        hasDocument: session.hasDocument,
      };
      navigate(`/purchase/plan/${await encrypt(purchasePayload)}`);
    }
  };

  return (
    <Card
      title={plan.name}
      actions={
        userPlan
          ? [
              <Popconfirm
                title="Tem certeza que deseja cancelar este plano?"
                okText="Sim"
                cancelText="Não"
                onConfirm={handlePlanButton}
              >
                <Button size="large" type="primary" danger={true} block>
                  Cancelar plano
                </Button>
              </Popconfirm>,
            ]
          : [
              <Button
                size="large"
                type="primary"
                block
                onClick={handlePlanButton}
              >
                Comprar agora
              </Button>,
            ]
      }
      style={{ width: 300 }}
      className={userPlan ? styles.myPlanCard : styles.planCard}
    >
      <div className={styles.planContent}>
        <div className={styles.priceWrapper}>
          <div className="price">R$ {Number(plan.price).toFixed(2)}</div>
          <div className="price-frequency">
            {plan.frequency === "ANUAL" ? "/Ano" : "/Mês"}
          </div>
        </div>
        <div className={styles.videosQuantityTag}>
          {plan.videosQuantity > 0
            ? plan.videosQuantity + " Vídeos"
            : "Vídeos ilimitados*"}
        </div>
        <div className={styles.planTitle}>{plan.description} </div>
        <div className={styles.planDescription}>{plan.priceDescription}</div>
      </div>
    </Card>
  );
};

export default PlanCard;
