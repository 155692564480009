import {
  APIErrorResponse,
  APIResponse,
  CreatePurchaseResponse,
  fallbackError,
  FinishPlanPurchaseResponse,
  FinishPurchaseResponse,
  GetUserByIdResponse,
  GetUserVideoResponse,
  GetVideosResponse,
  LoginResponse,
  UpdateUserResponse,
} from "./responses";
import Api from "./api";
import {
  CancelPlanPayload,
  CreatePurchasePayload,
  FinishPlanPurchasePayload,
  FinishPurchasePayload,
  GetUserVideoPayload,
  LoginPayload,
  RegisterUserPayload,
  SaveProfilePayload,
  VideoFiltersPayload,
} from "./payloads";
import { encrypt } from "./crypto";
import { saveUser } from "../auth/userService";

export const getVideos = async (
  payload?: VideoFiltersPayload
): Promise<GetVideosResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/videos`;
    const api = new Api();
    const arenasResponse = await api.get(originalUrl, {
      ...payload,
      pageSize: 300,
    });
    return { ...arenasResponse.data };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const registerUser = async (
  payload: RegisterUserPayload
): Promise<LoginResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/users/create`;
    const api = new Api();
    const enctyptedPayload = await encrypt(payload);
    const createUserResponse = await api.post(originalUrl, {
      payload: enctyptedPayload,
    });
    return { ...createUserResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const loginUser = async (
  payload: LoginPayload
): Promise<LoginResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/users/login`;
    const api = new Api();
    const enctyptedPayload = await encrypt(payload);
    const loginResponse = await api.post(originalUrl, {
      payload: enctyptedPayload,
    });
    return {
      ...loginResponse.data,
      isError: false,
    };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error.response.data));
  }
};

export const refreshUser = async (
  userId: string
): Promise<void | APIErrorResponse> => {
  try {
    const originalUrl = `/users/refresh/${userId}`;
    const api = new Api();
    const userResponse = await api.get(originalUrl);
    const userData = { ...userResponse.data } as LoginResponse;

    await saveUser(userData);
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getUserProfile = async (
  userId: string
): Promise<GetUserByIdResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/users/${userId}`;
    const api = new Api();
    const userResponse = await api.get(originalUrl);
    return { ...userResponse.data };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const updateProfile = async (
  payload: SaveProfilePayload
): Promise<UpdateUserResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/users`;
    const api = new Api();
    const enctyptedPayload = await encrypt(payload);
    const updateUserResponse = await api.patch(originalUrl, {
      payload: enctyptedPayload,
    });
    return { ...updateUserResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const createPurchase = async (
  payload: CreatePurchasePayload
): Promise<CreatePurchaseResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/purchase/video/create`;
    const api = new Api();
    const enctyptedPayload = await encrypt(payload);
    const createPurchaseResponse = await api.post(originalUrl, {
      payload: enctyptedPayload,
    });

    return { ...createPurchaseResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getUserVideo = async (
  payload: GetUserVideoPayload
): Promise<GetUserVideoResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/users/video/${payload.userId}/${payload.purchaseId}/${payload.videoId}`;
    const api = new Api();
    const videoResponse = await api.get(originalUrl);
    return { ...videoResponse.data };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const recoverPassword = async (
  email: string
): Promise<APIResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/users/refresh`;
    const api = new Api();
    const enctyptedPayload = await encrypt({ email });
    const recoverPasswordResponse = await api.post(originalUrl, {
      payload: enctyptedPayload,
    });
    if (recoverPasswordResponse.data) {
      return { isError: false };
    } else {
      return { isError: true };
    }
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const finishPurchase = async (
  payload: FinishPurchasePayload
): Promise<FinishPurchaseResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/purchase/payment`;
    const api = new Api();
    const enctyptedPayload = await encrypt({ ...payload });
    const finishPurchaseResponse = await api.post(originalUrl, {
      payload: enctyptedPayload,
    });

    return { ...finishPurchaseResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const finishPlanPurchase = async (
  payload: FinishPlanPurchasePayload
): Promise<FinishPlanPurchaseResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/purchase/plan/create`;
    const api = new Api();
    const enctyptedPayload = await encrypt({ ...payload });
    const finishPurchaseResponse = await api.post(originalUrl, {
      payload: enctyptedPayload,
    });

    return { url: finishPurchaseResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const cancelPlan = async (
  payload: CancelPlanPayload
): Promise<APIResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/purchase/plan/cancel`;
    const api = new Api();
    const enctyptedPayload = await encrypt({ ...payload });
    const cancelPlanResponse = await api.post(originalUrl, {
      payload: enctyptedPayload,
    });

    if (cancelPlanResponse.data) {
      return { isError: false };
    }
    return { isError: true };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};
