import { decrypt, encrypt } from "../services/crypto";
import { LoginResponse, LoginResponsePayload } from "../services/responses";
import { StatusType, Video } from "../services/types";
import Cookies from "js-cookie";
const cookieName = String(process.env.REACT_APP_USER_COOKIE);
const cartCookieName = "cartitems";

export const saveUser = async (user: LoginResponse) => {
  const encyptedUser = await encrypt(user);
  Cookies.set(cookieName, encyptedUser, { expires: 1 });
};

export const removeLoggedUser = async () => {
  clearCart();
  Cookies.remove(cookieName, { path: "/" });
};

const getActualPlan = (userData: LoginResponse) => {
  let actualPlan = userData.plans.find((plan) => plan.isDefault);

  if (userData.plans.length > 1) {
    const paidPlans = userData.plans.filter(
      (plan) =>
        plan.isActive &&
        !plan.isDefault &&
        plan.status === StatusType.CONFIRMADO
    );

    if (paidPlans.length > 0) {
      actualPlan = paidPlans.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      })[0];
    }
  }

  return actualPlan;
};

const getDefaultPlan = (userData: LoginResponse) => {
  return userData.plans.find((plan) => plan.isDefault);
};

export const getLoggedUser = () => {
  try {
    const userString = Cookies.get(cookieName);
    if (userString) {
      const userData = decrypt(userString);

      return {
        ...(userData as LoginResponse),
        actualPlan: getActualPlan(userData as LoginResponse),
        defaultPlan: getDefaultPlan(userData as LoginResponse),
        actualVideoCount:
          Number((userData as LoginResponse).actualVideoCount) ?? 0,
        hasPlan: (userData as LoginResponse).plans.length > 1,
      } as LoginResponsePayload;
    } else {
      return undefined;
    }
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getCartItems: () => Video[] = () => {
  const cartItemsString = Cookies.get(cartCookieName);
  if (cartItemsString) {
    return JSON.parse(cartItemsString);
  } else {
    return [];
  }
};

export const removeCartItem: (video: Video) => void = (video) => {
  const cookieCartItems = getCartItems();

  const removedItem = cookieCartItems.filter((item) => item.id !== video.id);

  Cookies.set(cartCookieName, JSON.stringify(removedItem));
};

export const clearCart: () => void = () => {
  Cookies.set(cartCookieName, JSON.stringify([]));
};

export const addToCart: (
  video: Video,
  callback?: (alreadyAdded: boolean) => void
) => void = (video: Video, callback) => {
  const cookieCartItems = getCartItems();
  let allCartItems: Video[] = [];
  let alreadyAdded = false;

  if (cookieCartItems.some((item) => item.id === video.id)) {
    allCartItems = [...cookieCartItems];
    alreadyAdded = true;
  } else {
    allCartItems = [...cookieCartItems, video];
  }

  Cookies.set(cartCookieName, JSON.stringify(allCartItems));
  callback && callback(alreadyAdded);
};
