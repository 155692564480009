import {
  APIErrorResponse,
  CreateArenaResponse,
  CreateFieldResponse,
  CreatePlanResponse,
  UpdateUserResponse,
  fallbackError,
  GetArenasResponse,
  GetFieldsResponse,
  GetPlansResponse,
  GetUsersResponse,
  GetUserByIdResponse,
  APIResponse,
  GetAdsResponse,
  GetAdByIdResponse,
  GetArenaByIdResponse,
  GetFiedlByIdResponse,
  GetPlanByIdResponse,
  GetPurchasesVideosListResponse,
  GetPurchasesPlansListResponse,
} from "./responses";
import Api from "./api";
import {
  CreateArenaPayload,
  CreateFieldPayload,
  CreateOrUpdatePlanPayload,
  PurchasePlansFiltersPayload,
  PurchaseVideosFiltersPayload,
  UpdateAdsPayload,
  UpdateUserPayload,
  UploadImagePayload,
} from "./payloads";
import { encrypt } from "./crypto";

export const getArenas = async (): Promise<
  GetArenasResponse | APIErrorResponse
> => {
  try {
    const originalUrl = `/arenas`;
    const api = new Api();
    const arenasResponse = await api.get(originalUrl);
    return { ...arenasResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getFields = async (): Promise<
  GetFieldsResponse | APIErrorResponse
> => {
  try {
    const originalUrl = `/fields`;
    const api = new Api();
    const fieldsResponse = await api.get(originalUrl);
    return { ...fieldsResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getUsers = async (): Promise<
  GetUsersResponse | APIErrorResponse
> => {
  try {
    const originalUrl = `/users`;
    const api = new Api();
    const usersResponse = await api.get(originalUrl);
    return { ...usersResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getPlans = async (): Promise<
  GetPlansResponse | APIErrorResponse
> => {
  try {
    const originalUrl = `/plans`;
    const api = new Api();
    const fieldsResponse = await api.get(originalUrl);
    return { ...fieldsResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const createArena = async (
  payload: CreateArenaPayload
): Promise<CreateArenaResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/arenas`;
    const api = new Api();
    const enctyptedPayload = await encrypt(payload);
    const createArenaResponse = await api.post(originalUrl, {
      payload: enctyptedPayload,
    });
    return { ...createArenaResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const createField = async (
  payload: CreateFieldPayload
): Promise<CreateFieldResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/fields`;
    const api = new Api();
    const enctyptedPayload = await encrypt(payload);
    const createFieldResponse = await api.post(originalUrl, {
      payload: enctyptedPayload,
    });
    return { ...createFieldResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const updateUser = async (
  payload: UpdateUserPayload
): Promise<UpdateUserResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/users`;
    const api = new Api();
    const enctyptedPayload = await encrypt(payload);
    const updateUserResponse = await api.post(originalUrl, {
      payload: enctyptedPayload,
    });
    return { ...updateUserResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const createOrUpdatePlan = async (
  payload: CreateOrUpdatePlanPayload
): Promise<CreatePlanResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/plans`;
    const api = new Api();
    const enctyptedPayload = await encrypt(payload);
    const createOrUpdatePlanResponse = await api.post(originalUrl, {
      payload: enctyptedPayload,
    });
    return { ...createOrUpdatePlanResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const uploadImage = async (
  payload: UploadImagePayload
): Promise<string | APIErrorResponse> => {
  try {
    const originalUrl = `/image/upload`;
    const api = new Api();
    const createArenaResponse = await api.post(originalUrl, payload);
    return createArenaResponse.data;
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getUserById = async (
  id: string
): Promise<GetUserByIdResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/users/${id}`;
    const api = new Api();
    const getUserResponse = await api.get(originalUrl);
    return { ...getUserResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getAds = async (): Promise<GetAdsResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/ads`;
    const api = new Api();
    const adsResponse = await api.get(originalUrl);
    return { ...adsResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getAdById = async (
  id: string
): Promise<GetAdByIdResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/ads/${id}`;
    const api = new Api();
    const adResponse = await api.get(originalUrl);
    return { ...adResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const updateAd = async (
  payload: UpdateAdsPayload
): Promise<APIResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/ads`;
    const api = new Api();
    const enctyptedPayload = await encrypt(payload);
    const updateAdsResponse = await api.post(originalUrl, {
      payload: enctyptedPayload,
    });
    if (updateAdsResponse.data.id) {
      return { isError: false };
    } else {
      return { isError: true };
    }
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getArenaById = async (
  id: string
): Promise<GetArenaByIdResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/arenas/${id}`;
    const api = new Api();
    const arenaResponse = await api.get(originalUrl);
    return { ...arenaResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getPlanById = async (
  id: string
): Promise<GetPlanByIdResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/plans/${id}`;
    const api = new Api();
    const planResponse = await api.get(originalUrl);
    return { ...planResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getFieldById = async (
  id: string
): Promise<GetFiedlByIdResponse | APIErrorResponse> => {
  try {
    const originalUrl = `/fields/${id}`;
    const api = new Api();
    const fieldResponse = await api.get(originalUrl);
    return { ...fieldResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getVideosPurchases = async (
  payload: PurchaseVideosFiltersPayload
): Promise<GetPurchasesVideosListResponse | APIErrorResponse> => {
  try {
    const { adminId } = payload;
    const queryParams = new URLSearchParams({ adminId }).toString();
    const originalUrl = `/purchases/videos?${queryParams}`;
    const api = new Api();
    const usersResponse = await api.get(originalUrl);
    return { ...usersResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};

export const getPlansPurchases = async (
  payload: PurchasePlansFiltersPayload
): Promise<GetPurchasesPlansListResponse | APIErrorResponse> => {
  try {
    const { adminId } = payload;
    const queryParams = new URLSearchParams({ adminId }).toString();
    const originalUrl = `/purchases/plans?${queryParams}`;
    const api = new Api();
    const usersResponse = await api.get(originalUrl);
    return { ...usersResponse.data, isError: false };
  } catch (error: any) {
    console.log(error);
    return fallbackError(String(error));
  }
};
