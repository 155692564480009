import React, { useCallback, useEffect, useState } from "react";
import AppLayout from "../../components/adminLayout";
import styles from "./styles.module.css";
import MainContent from "../../components/mainContent";
import { Form, Result } from "antd";
import { Button, Image, Input, Select, Space, Upload } from "antd/lib";
import { State, City, IState, ICity } from "country-state-city";
import {
  DeleteFilled,
  EnvironmentFilled,
  InboxOutlined,
  PictureFilled,
} from "@ant-design/icons";
import { IoMdFootball } from "react-icons/io";
import { rcFileToBase64 } from "../../utils/imageHelper";
import { CreateArenaPayload } from "../../services/payloads";
import {
  createArena,
  getArenaById,
  uploadImage,
} from "../../services/adminFunctions";
import useMessage from "antd/lib/message/useMessage";
import { APIErrorResponse } from "../../services/responses";
import { MaskedInput } from "antd-mask-input";
import { getLoggedUser } from "../../auth/userService";
import { useNavigate, useParams } from "react-router-dom";
import { Arena } from "../../services/types";
import Loader from "../../components/loader";

const EditArena: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [stateList, setStateList] = useState<IState[]>([]);
  const [cityList, setCityList] = useState<ICity[]>([]);
  const [arenaLogo, setArenaLogo] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [message, contextHolder] = useMessage();
  const session = getLoggedUser();
  const [arenaError, setArenaError] = useState(false);
  const [loadingArena, setLoadingArena] = useState(false);

  let ADMIN_ID = "e8e39acf-dd44-47dd-bb9b-bae8516f4433";

  if (session && typeof session !== "string") {
    ADMIN_ID = session.id;
  }

  const normFile = async (e: any) => {
    const base64File = await rcFileToBase64(e);
    setArenaLogo(base64File);
    if (Array.isArray(e)) {
      return e;
    }

    return e?.file;
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const needsUpload = arenaLogo && !arenaLogo.startsWith("https://");

    const uploadedImageUrl = !needsUpload
      ? arenaLogo
      : await uploadImage({ image: String(arenaLogo) });
    if (needsUpload && (uploadedImageUrl as APIErrorResponse).isError) {
      message.error(
        "Erro ao enviar imagem! Verifique sua conexão ou tente novamente mais tarde."
      );
      return;
    }

    delete values.dragger;
    //TODO validar se vai deletar emails
    delete values.email;
    const payload: CreateArenaPayload = {
      ...values,
      arenaId: id,
      address: {
        country: "Brasil",
        ...values.address,
      },
      userId: ADMIN_ID,
      logoImg: uploadedImageUrl,
      contactPhone: values.phone,
      contactName: values.name,
      comission: Number(values.comission),
    };
    const createArenaResponse = await createArena(payload);
    if (!createArenaResponse.isError) {
      message.success("Arena atualizada com sucesso!");
      navigate("/admin/arenas");
    } else {
      message.error(
        "Erro ao atualizar Arena! Verifique sua conexão ou tente novamente mais tarde."
      );
    }

    setLoading(false);
  };

  const getAllStates = useCallback(() => {
    setStateList(State.getStatesOfCountry("BR"));
  }, []);

  const getCities = useCallback((stateCode: string) => {
    setCityList(City.getCitiesOfState("BR", stateCode));
  }, []);

  const getArena = useCallback(async () => {
    setArenaError(false);
    if (id) {
      setLoadingArena(true);
      const getArenaResponse = await getArenaById(String(id));
      if (!getArenaResponse.isError) {
        form.setFieldsValue(getArenaResponse);
        setArenaLogo((getArenaResponse as Arena).logoImg);
      } else {
        setArenaError(true);
      }
      setLoadingArena(false);
    }
  }, [id, form]);

  useEffect(() => {
    getAllStates();
    getArena();
  }, [getAllStates, getArena]);

  return (
    <AppLayout title="Adicionar arena">
      <>
        {contextHolder}
        <MainContent>
          {loadingArena ? (
            <Loader />
          ) : arenaError ? (
            <Result
              status="error"
              title="Falha ao obter dados da Arena"
              subTitle="Por favor, verifique sua conexão com a internet e tente novamente."
              extra={[
                <Button type="primary" key="console" onClick={() => getArena()}>
                  Tentar novamente
                </Button>,
                <Button key="buy" onClick={() => navigate(-1)}>
                  Voltar
                </Button>,
              ]}
            />
          ) : (
            <Form
              form={form}
              className={styles.form}
              name="complex-form"
              onFinish={onFinish}
              labelCol={{ span: 32 }}
              wrapperCol={{ span: 32 }}
              layout="vertical"
            >
              <Form.Item
                label={
                  <>
                    <PictureFilled style={{ marginRight: 8 }} /> Logo da arena
                  </>
                }
                wrapperCol={{ span: 4 }}
              >
                <Form.Item
                  name="dragger"
                  valuePropName="imageFile"
                  noStyle
                  rules={[
                    {
                      required: arenaLogo === undefined,
                      message: "Escolha um logo para a Arena!",
                    },
                  ]}
                >
                  {arenaLogo ? (
                    <div className={styles.logoWrapper}>
                      <Image width={150} src={arenaLogo} preview={false} />
                      <Button
                        danger
                        type="primary"
                        onClick={() => setArenaLogo(undefined)}
                        icon={<DeleteFilled />}
                      >
                        Remover?
                      </Button>
                    </div>
                  ) : (
                    <Upload.Dragger
                      name="dragger"
                      multiple={false}
                      accept="image/*"
                      action={normFile}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Selecione ou arraste seu arquivo de imagem
                      </p>
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <IoMdFootball style={{ marginRight: 8 }} /> Dados da arena
                  </>
                }
              >
                <Space wrap>
                  <Form.Item
                    name="name"
                    label="Nome da arena"
                    rules={[
                      { required: true, message: "Nome da arena obrigatório" },
                    ]}
                  >
                    <Input placeholder="Nome da arena" />
                  </Form.Item>
                  {/* <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      { required: true, message: "Insira seu e-mail!" },
                      {
                        pattern: new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                        message: "E-mail inválido!",
                      },
                    ]}
                  >
                    <Input placeholder="E-mail" />
                  </Form.Item> */}
                  <Form.Item
                    name="document"
                    label="CPF/CNPJ"
                    rules={[
                      { required: true, message: "CPF/CNPJ obrigatório" },
                      {
                        pattern: new RegExp(
                          "^\\d{2}\\.\\d{3}\\.\\d{3}/\\d{4}-\\d{2}$"
                        ),
                        message: "Número de CNPJ inválido!",
                      },
                    ]}
                  >
                    <MaskedInput
                      mask={"00.000.000/0000-00"}
                      placeholder="00.000.000/0000-00"
                    />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    label="Celular"
                    rules={[
                      {
                        required: true,
                        message: "Número de celular obrigatório",
                      },
                      {
                        pattern: new RegExp(/^\d{2} \d \d{4}-\d{4}$/),
                        message: "Número de celular inválido!",
                      },
                    ]}
                  >
                    <MaskedInput
                      mask={"00 0 0000-0000"}
                      placeholder="99 9 9999-9999"
                    />
                  </Form.Item>
                  <Form.Item
                    name="comission"
                    label="Comissão"
                    rules={[
                      { required: true, message: "Comissão obrigatória" },
                    ]}
                  >
                    <Input type="number" placeholder="0" addonBefore="%" />
                  </Form.Item>
                </Space>
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <EnvironmentFilled style={{ marginRight: 8 }} /> Endereço
                  </>
                }
              >
                <Space wrap>
                  <Form.Item
                    name={["address", "state"]}
                    label="Estado"
                    rules={[{ required: true, message: "Selecione um estado" }]}
                  >
                    <Select
                      placeholder="Estado"
                      showSearch
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      autoComplete="new-state"
                      options={stateList.map((c) => ({
                        label: c.name,
                        value: c.isoCode,
                      }))}
                      onChange={(option) => getCities(option)}
                    ></Select>
                  </Form.Item>
                  <Form.Item
                    name={["address", "city"]}
                    label="Cidade"
                    rules={[{ required: true, message: "Selecione a cidade" }]}
                  >
                    <Select
                      placeholder="Cidade"
                      showSearch
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      autoComplete="new-state"
                      options={cityList.map((c) => ({
                        label: c.name,
                        value: c.name,
                      }))}
                    ></Select>
                  </Form.Item>
                  <Form.Item
                    name={["address", "zip"]}
                    label="CEP"
                    rules={[
                      { required: true, message: "CEP obrigatório" },
                      {
                        pattern: new RegExp(/^\d{5}-\d{3}$/),
                        message: "CEP inválido!",
                      },
                    ]}
                  >
                    <MaskedInput mask={"00000-000"} placeholder="00000-000" />
                  </Form.Item>
                  <Form.Item
                    name={["address", "street"]}
                    label="Logradouro"
                    rules={[
                      { required: true, message: "Logradouro obrigatório" },
                    ]}
                  >
                    <Input placeholder="Logradouro" maxLength={256} />
                  </Form.Item>
                  <Form.Item
                    name={["address", "district"]}
                    label="Bairro"
                    rules={[{ required: true, message: "Bairro obrigatório" }]}
                  >
                    <Input placeholder="Bairro" maxLength={256} />
                  </Form.Item>
                  <Form.Item
                    name={["address", "number"]}
                    label="Número"
                    rules={[{ required: true, message: "Número obrigatório" }]}
                  >
                    <Input placeholder="Número" />
                  </Form.Item>
                  <Form.Item
                    name={["address", "complement"]}
                    label="Complemento"
                    rules={[
                      { required: true, message: "Insira um complemento" },
                    ]}
                  >
                    <Input
                      placeholder="Perímetro, descrição do local, etc..."
                      maxLength={256}
                    />
                  </Form.Item>
                </Space>
              </Form.Item>

              <Form.Item label=" " noStyle colon={false}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Salvar arena
                </Button>
              </Form.Item>
            </Form>
          )}
        </MainContent>
      </>
    </AppLayout>
  );
};

export default EditArena;
