export type Address = {
  id: string;
  zip: string;
  country: string;
  state: string;
  city: string;
  street: string;
  district: string;
  number: number;
  complement: string;
  createdAt: string;
};

export type Arena = {
  id: string;
  name: string;
  document: string;
  phone: string;
  logoImg: string;
  isActive: boolean;
  address: Address;
  createdAt: string;
};

export type Video = {
  id: string;
  name: string;
  preview: string;
  uri: string;
  fieldId: string;
  createdAt: string;
  recordedAt: string;
  fieldDescription: string;
  arenaName: string;
  purchaseId?: string;
  arenaLogo: string;
  videoPrice: number;
  videoFinalPrice?: number;
  finalPriceMotive?: "DEFAULT" | "UNLIMITED" | "NORMAL";
};

export type Field = {
  id: string;
  description: string;
  equipment: string;
  createdAt: string;
  arenaId: string;
  arenaName: string;
  logoImg: string;
};

export type User = {
  id: string;
  name: string;
  email: string;
  type: "STANDARD" | "PRIVILEGED" | "ARENA";
  avatarImg: string | null;
};

export enum StatusType {
  PENDENTE = "PENDENTE",
  CONFIRMADO = "CONFIRMADO",
  CANCELADO = "CANCELADO",
  CANCELADO_RECEBEDOR = "CANCELADO_RECEBEDOR",
}

export type Plan = {
  id: string;
  name: string;
  price: number;
  videosQuantity: number;
  videoPrice: number;
  discount: number;
  frequency: "MENSAL" | "ANUAL";
  description: string;
  priceDescription: string;
  isActive: boolean;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
};

export type Ads = {
  id: string;
  name: string;
  img: string;
};

export type PurchaseData = {
  userId: string;
  discount: number;
  finalPrice: number;
  items: number;
  price: number;
  videos: string[];
  cartItems: Video[];
  payer: User;
  hasDocument: boolean;
  externalReference?: string;
};

export type PlanPurchaseData = {
  userId: string;
  planId: string;
  planName: string;
  videosQuantity: number;
  price: number;
  payer: User;
  hasDocument: boolean;
  externalReference?: string;
};

export type ListPurchasesVideosData = {
  id: string;
  status: "PENDENTE" | "CONFIRMADO" | "CANCEADO" | "CANCELADO_RECEBEDOR";
  finalPrice: number;
  purchasedAt: Date;
  customerName: string;
  planId: string;
  planName: string;
  totalVideos: number;
};

export type ListPurchasesPlansData = {
  id: string;
  customerName: string;
  finalPrice: number;
  planType: "NORMAL" | "ILIMITADO";
  status: "PENDENTE" | "CONFIRMADO" | "CANCEADO" | "CANCELADO_RECEBEDOR";
  purchasedAt: Date;
  nextPaymentDate: Date;
};
