import React, { useCallback, useEffect, useState } from "react";
import AppLayout from "../../components/adminLayout";
import styles from "./styles.module.css";
import MainContent from "../../components/mainContent";
import { Tabs } from "antd/lib";
import { PlaySquareFilled, TagFilled } from "@ant-design/icons";
import VideoSalesTable from "../../components/tables/videoSalesTable";
import PlansSalesTable from "../../components/tables/plansSalesTable";
import {
  ListPurchasesPlansData,
  ListPurchasesVideosData,
} from "../../services/types";
import { getLoggedUser } from "../../auth/userService";
import {
  getPlansPurchases,
  getVideosPurchases,
} from "../../services/adminFunctions";
import {
  GetPurchasesPlansListResponse,
  GetPurchasesVideosListResponse,
} from "../../services/responses";

const Sales: React.FC = () => {
  const session = getLoggedUser();

  let ADMIN_ID = "";
  if (session && typeof session !== "string") {
    ADMIN_ID = session.id;
  }

  const [activeContent, setActiveContent] = useState<"videos" | "plans">(
    "videos"
  );

  const [purchasesVideos, setPurchasesVideos] = useState<
    ListPurchasesVideosData[]
  >([]);
  const [loadingPurchasesVideos, setLoadingPurchasesVideos] = useState(false);

  const getPurchasesVideosList = useCallback(async () => {
    setLoadingPurchasesVideos(true);
    const getAllPurchasesVideosResponse = await getVideosPurchases({
      adminId: ADMIN_ID,
    });
    if (!getAllPurchasesVideosResponse.isError) {
      const res = (
        getAllPurchasesVideosResponse as GetPurchasesVideosListResponse
      ).data;
      setPurchasesVideos(res);
    } else {
      // TODO tratar erro
    }
    setLoadingPurchasesVideos(false);
  }, [ADMIN_ID]);

  useEffect(() => {
    getPurchasesVideosList();
  }, [getPurchasesVideosList]);

  const [purchasesPlans, setPurchasesPlans] = useState<
    ListPurchasesPlansData[]
  >([]);
  const [loadingPurchasesPlans, setLoadingPurchasesPlans] = useState(false);

  const getPurchasesPlansList = useCallback(async () => {
    setLoadingPurchasesPlans(true);
    const getAllPurchasesPlansResponse = await getPlansPurchases({
      adminId: ADMIN_ID,
    });
    if (!getAllPurchasesPlansResponse.isError) {
      const res = (
        getAllPurchasesPlansResponse as GetPurchasesPlansListResponse
      ).data;
      setPurchasesPlans(res);
    } else {
      // TODO tratar erro
    }
    setLoadingPurchasesPlans(false);
  }, [ADMIN_ID]);

  useEffect(() => {
    getPurchasesPlansList();
  }, [getPurchasesPlansList]);

  return (
    <AppLayout title="Vendas">
      <>
        <MainContent
          header={
            <Tabs
              className={styles.tabs}
              hideAdd
              onChange={(props) =>
                setActiveContent(props as "videos" | "plans")
              }
              activeKey={activeContent}
              type="card"
              size="large"
              items={[
                {
                  label: "Vídeos",
                  key: "videos",
                  icon: <PlaySquareFilled />,
                },
                {
                  label: "Planos",
                  key: "plans",
                  icon: <TagFilled />,
                },
              ]}
            />
          }
        >
          {activeContent === "videos" ? (
            <VideoSalesTable
              lisVideosPurchases={purchasesVideos}
              loading={loadingPurchasesVideos}
            />
          ) : (
            <PlansSalesTable
              listPlansPurchases={purchasesPlans}
              loading={loadingPurchasesPlans}
            />
          )}
        </MainContent>
      </>
    </AppLayout>
  );
};

export default Sales;
