import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  ConfigProvider,
  Drawer,
  FloatButton,
  Form,
  Image,
  Input,
  List,
  Select,
  Tooltip,
  Upload,
} from "antd/lib";
import { antdTheme } from "../../config/theme";
// import { useNavigate } from "react-router-dom";
import locale from "antd/locale/pt_BR";

import Loader from "../../components/loader";
import { getLoggedUser } from "../../auth/userService";
import UserHeader from "../../components/userHeader";
import {
  DeleteFilled,
  EditFilled,
  EnvironmentFilled,
  SwapOutlined,
} from "@ant-design/icons";
import { rcFileToBase64 } from "../../utils/imageHelper";
import { IoMdFootball } from "react-icons/io";
import { Space } from "antd";
import { City, ICity, IState, State } from "country-state-city";
import { Arena, User } from "../../services/types";
import { getArenas, uploadImage } from "../../services/adminFunctions";
import {
  APIErrorResponse,
  GetArenasResponse,
  GetUserByIdResponse,
} from "../../services/responses";
import ArenasTable from "../../components/tables/arenasTable";
import { RiUserFill } from "react-icons/ri";
import { getUserProfile, updateProfile } from "../../services/userFunctions";
import useMessage from "antd/lib/message/useMessage";
import { SaveProfilePayload } from "../../services/payloads";
import { MaskedInput } from "antd-mask-input";

const Profile: React.FC = () => {
  // const navigate = useNavigate();
  const session: string | User | undefined = getLoggedUser();
  const [form] = Form.useForm();
  const [message, contextHolder] = useMessage();
  const [openCart, setOpenCart] = useState(false);
  const [openArenas, setOpenArenas] = useState(false);
  const [profilePic, setProfilePic] = useState<string | undefined>(undefined);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [stateList, setStateList] = useState<IState[]>([]);
  const [cityList, setCityList] = useState<ICity[]>([]);
  const [arenas, setArenas] = useState<Arena[]>([]);
  const [loadingArenas, setLoadingArenas] = useState(false);
  const [selectedArena, setSelectedArena] = useState<Arena | undefined>(
    undefined
  );
  const [sending, setSending] = useState(false);
  const [profileData, setProfileData] = useState<
    GetUserByIdResponse | undefined
  >(undefined);

  const getProfileData = useCallback(async () => {
    setLoadingProfile(true);
    if (session && typeof session !== "string") {
      const getProfileResponse = await getUserProfile(session.id);
      if (!getProfileResponse.isError) {
        form.setFieldsValue(getProfileResponse);
        const res = getProfileResponse as GetUserByIdResponse;
        setProfilePic(res.avatarImg || undefined);
        setProfileData(res);
      } else {
        // TODO tratar erro
      }
    }
    setLoadingProfile(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileData && profileData.favoriteArenaId) {
      const arena = arenas.find(
        (arena) => arena.id === profileData.favoriteArenaId
      );
      setSelectedArena(arena || undefined);
    }
  }, [profileData, arenas]);

  const getAllArenas = useCallback(async () => {
    setLoadingArenas(true);
    const getAllArenasResponse = await getArenas();
    if (!getAllArenasResponse.isError) {
      const res = (getAllArenasResponse as GetArenasResponse).data;
      setArenas(res);
    } else {
      // TODO tratar erro
    }
    setLoadingArenas(false);
  }, []);

  useEffect(() => {
    getAllArenas();
    getProfileData();
  }, [getAllArenas, getProfileData]);

  const onFinish = async (values: any) => {
    setSending(true);
    let imgUrl: string | undefined = undefined;
    if (session && typeof session !== "string") {
      if (profilePic !== undefined && !profilePic.startsWith("https")) {
        message.info("Fazendo upload da sua foto de perfil...");
        const uploadedImageUrl = await uploadImage({
          image: String(profilePic),
        });

        if (!(uploadedImageUrl as APIErrorResponse).isError) {
          imgUrl = uploadedImageUrl as string;
        } else {
          message.error(
            "Erro ao enviar imagem! Verifique sua conexão ou tente novamente mais tarde."
          );
        }
      }

      delete values.dragger;
      const payload: SaveProfilePayload = {
        ...values,
        address: {
          country: "Brasil",
          ...values.address,
        },
        userId: session.id,
        avatarImg: imgUrl,
        favoriteArenaId: selectedArena?.id || undefined,
      };
      const updateProfileResponse = await updateProfile(payload);
      if (!updateProfileResponse.isError) {
        message.success("Perfil atualizado com sucesso!");
      } else {
        message.error(
          "Erro ao atualizar perfil! Verifique sua conexão ou tente novamente mais tarde."
        );
      }
    }
    setSending(false);
  };

  const normFile = async (e: any) => {
    const base64File = await rcFileToBase64(e);
    setProfilePic(base64File);
    if (Array.isArray(e)) {
      return e;
    }

    return e?.file;
  };

  const getAllStates = useCallback(() => {
    setStateList(State.getStatesOfCountry("BR"));
  }, []);

  const getCities = useCallback((stateCode: string) => {
    setCityList(City.getCitiesOfState("BR", stateCode));
  }, []);

  useEffect(() => {
    getAllStates();
  }, [getAllStates]);

  return (
    <ConfigProvider theme={antdTheme} locale={locale}>
      {contextHolder}
      <Drawer
        classNames={{ wrapper: styles.arenasDrawerContent }}
        title="Selecione uma Arena"
        placement="bottom"
        onClose={() => setOpenArenas(false)}
        open={openArenas}
      >
        <ArenasTable
          arenas={arenas}
          loading={loadingArenas}
          onRowClick={(arena) => {
            setSelectedArena(arena);
            setOpenArenas(false);
          }}
        />
      </Drawer>
      <UserHeader
        openCartPopoverState={openCart}
        setOpenCartPopoverState={setOpenCart}
      />
      <section className={styles.main}>
        <div className={styles.profileWrapper}>
          <Card>
            {loadingProfile ? (
              <div className={styles.loaderWrapper}>
                <Loader />
              </div>
            ) : (
              <Form
                form={form}
                className={styles.form}
                name="complex-form"
                onFinish={onFinish}
                labelCol={{ span: 32 }}
                wrapperCol={{ span: 32 }}
                layout="vertical"
              >
                <Form.Item
                  wrapperCol={{ span: 32 }}
                  className={styles.profilePicWrapper}
                >
                  <Form.Item name="dragger" valuePropName="imageFile" noStyle>
                    {profilePic ? (
                      <div className={styles.logoWrapper}>
                        <Image width={150} src={profilePic} preview={false} />
                        <FloatButton
                          type="primary"
                          onClick={() => setProfilePic(undefined)}
                          icon={<DeleteFilled />}
                        />
                      </div>
                    ) : (
                      <Upload.Dragger
                        name="dragger"
                        multiple={false}
                        accept="image/*"
                        action={normFile}
                        className={styles.logoWrapper}
                      >
                        <Image width={150} src="/avatar.svg" preview={false} />
                        <FloatButton type="primary" icon={<EditFilled />} />
                      </Upload.Dragger>
                    )}
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      <RiUserFill style={{ marginRight: 8 }} /> Meus dados
                    </>
                  }
                >
                  <Space wrap>
                    <Form.Item
                      name="name"
                      label="Nome completo"
                      rules={[
                        {
                          required: true,
                          message: "Nome completo obrigatório",
                        },
                      ]}
                    >
                      <Input placeholder="Nome completo" />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[
                        { required: true, message: "E-mail obrigatório" },
                      ]}
                    >
                      <Input placeholder="E-mail" />
                    </Form.Item>
                    <Form.Item
                      name="document"
                      label="CPF"
                      rules={[
                        { required: true, message: "CPF obrigatório" },
                        {
                          pattern: new RegExp(
                            "^\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2}$"
                          ),
                          message: "Número de CPF inválido!",
                        },
                      ]}
                    >
                      <MaskedInput
                        mask={"000.000.000-00"}
                        placeholder="000.000.000-00"
                      />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label="Celular"
                      rules={[
                        {
                          required: true,
                          message: "Número de celular obrigatório",
                        },
                        {
                          pattern: new RegExp(/^\d{2} \d \d{4}-\d{4}$/),
                          message: "Número de celular inválido!",
                        },
                      ]}
                    >
                      <MaskedInput
                        mask={"00 0 0000-0000"}
                        placeholder="99 9 9999-9999"
                      />
                    </Form.Item>
                    <Form.Item label="Senha" name="password">
                      <Input.Password />
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      <EnvironmentFilled style={{ marginRight: 8 }} /> Endereço
                    </>
                  }
                >
                  <Space wrap>
                    <Form.Item
                      name={["address", "state"]}
                      label="Estado"
                      rules={[
                        { required: true, message: "Selecione um estado" },
                      ]}
                    >
                      <Select
                        placeholder="Estado"
                        showSearch
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        autoComplete="new-state"
                        options={stateList.map((c) => ({
                          label: c.name,
                          value: c.isoCode,
                        }))}
                        onChange={(option) => getCities(option)}
                      ></Select>
                    </Form.Item>
                    <Form.Item
                      name={["address", "city"]}
                      label="Cidade"
                      rules={[
                        { required: true, message: "Selecione a cidade" },
                      ]}
                    >
                      <Select
                        placeholder="Cidade"
                        showSearch
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        autoComplete="new-state"
                        options={cityList.map((c) => ({
                          label: c.name,
                          value: c.name,
                        }))}
                      ></Select>
                    </Form.Item>
                    <Form.Item
                      name={["address", "zip"]}
                      label="CEP"
                      rules={[
                        { required: true, message: "CEP obrigatório" },
                        {
                          pattern: new RegExp(/^\d{5}-\d{3}$/),
                          message: "CEP inválido!",
                        },
                      ]}
                    >
                      <MaskedInput mask={"00000-000"} placeholder="00000-000" />
                    </Form.Item>
                    <Form.Item
                      name={["address", "street"]}
                      label="Logradouro"
                      rules={[
                        { required: true, message: "Logradouro obrigatório" },
                      ]}
                    >
                      <Input placeholder="Logradouro" maxLength={256} />
                    </Form.Item>
                    <Form.Item
                      name={["address", "district"]}
                      label="Bairro"
                      rules={[
                        { required: true, message: "Bairro obrigatório" },
                      ]}
                    >
                      <Input placeholder="Bairro" maxLength={256} />
                    </Form.Item>
                    <Form.Item
                      name={["address", "number"]}
                      label="Número"
                      rules={[
                        { required: true, message: "Número obrigatório" },
                        {
                          max: 6,
                          message: "Número inválido!",
                        },
                      ]}
                    >
                      <Input placeholder="Número" type="number" />
                    </Form.Item>
                    <Form.Item
                      name={["address", "complement"]}
                      label="Complemento"
                    >
                      <Input
                        placeholder="Perímetro, descrição do local, etc..."
                        maxLength={256}
                      />
                    </Form.Item>
                  </Space>
                </Form.Item>

                <Form.Item
                  label={
                    <>
                      <IoMdFootball style={{ marginRight: 8 }} /> Arena favorita
                    </>
                  }
                >
                  <Space wrap>
                    <Form.Item name="arena" label="">
                      {/* <Input placeholder="Nome do campo" /> */}
                      {selectedArena ? (
                        <List
                          className={styles.arenaItem}
                          dataSource={["1"]}
                          renderItem={() => (
                            <List.Item
                              onClick={() => setOpenArenas(true)}
                              actions={[
                                <Tooltip title="Alterar Arena">
                                  <Button
                                    type="primary"
                                    icon={<SwapOutlined />}
                                  />
                                  ,
                                </Tooltip>,
                              ]}
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    shape="square"
                                    alt="arena logo"
                                    src={selectedArena.logoImg}
                                  />
                                }
                                title={selectedArena.name}
                                description="Arena selecionada"
                              />
                            </List.Item>
                          )}
                        />
                      ) : (
                        <Alert
                          banner
                          action={
                            <Button
                              type="primary"
                              style={{ marginLeft: 12 }}
                              onClick={() => setOpenArenas(true)}
                            >
                              Selecionar Arena
                            </Button>
                          }
                          type="error"
                          showIcon
                          message="Nenhuma arena selecionada"
                        />
                      )}
                    </Form.Item>
                  </Space>
                </Form.Item>

                <Form.Item label=" " noStyle colon={false}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={sending || loadingProfile}
                  >
                    Salvar alterações
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Card>
        </div>
      </section>
    </ConfigProvider>
  );
};

export default Profile;
