import React from "react";

import styles from "./styles.module.css";
import { PurchaseData } from "../../services/types";
import { Space } from "antd/lib";
import VideoCartItem from "../videoCartItem";
import { Divider, Typography } from "antd";

type PurchaseSumaryProps = {
  purchase: PurchaseData;
};

const PurchaseSumary: React.FC<PurchaseSumaryProps> = ({ purchase }) => {
  return (
    <div className={styles.sumaryWrapper}>
      <Typography.Title level={4}>Resumo da compra:</Typography.Title>
      <Space
        direction="vertical"
        style={{ width: "100%" }}
        className={styles.videosWrapper}
      >
        {purchase.cartItems.map((video) => (
          <VideoCartItem hideRemove video={video} removeCallback={() => {}} />
        ))}
      </Space>
      <Divider dashed></Divider>
      <div className={styles.actions}>
        <div className={styles.sumary}>
          Total:{" "}
          <strong>
            {purchase.cartItems
              .reduce(
                (total, video) => total + parseFloat(String(video.videoPrice)),
                0
              )
              .toFixed(2)}
          </strong>
        </div>
        <div className={styles.sumary}>
          Descontos
          <strong>
            -{" "}
            {(
              purchase.cartItems.reduce(
                (sum, video) => sum + parseFloat(String(video.videoPrice)),
                0
              ) -
              purchase.cartItems.reduce(
                (sum, video) => sum + parseFloat(String(video.videoFinalPrice)),
                0
              )
            ).toFixed(2)}
          </strong>
        </div>
        <div className={styles.sumary}>
          Valor a pagar:{" "}
          <strong>
            {" "}
            {purchase.cartItems
              .reduce(
                (total, video) =>
                  total + parseFloat(String(video.videoFinalPrice)),
                0
              )
              .toFixed(2)}
          </strong>
        </div>
      </div>
    </div>
  );
};

export default PurchaseSumary;
