import React from "react";
import styles from "./styles.module.css";
import { PlanPurchaseData } from "../../services/types";
import { Divider, Typography } from "antd";

type PurchaseSumaryProps = {
  purchase: PlanPurchaseData;
};

const PlanPurchaseSummary: React.FC<PurchaseSumaryProps> = ({ purchase }) => {
  return (
    <div className={styles.sumaryWrapper}>
      <Typography.Title level={4}>Resumo da compra:</Typography.Title>
      <Divider dashed></Divider>
      <div className={styles.actions}>
        <div className={styles.sumary}>
          Plano contratado: <strong>{purchase.planName}</strong>
        </div>
        <div className={styles.sumary}>
          Valor a pagar: <strong> {purchase.price.toFixed(2)}</strong>
        </div>
      </div>
    </div>
  );
};

export default PlanPurchaseSummary;
