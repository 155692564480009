import { Weekday } from "./payloads";
import {
  Address,
  Ads,
  Arena,
  Field,
  ListPurchasesPlansData,
  ListPurchasesVideosData,
  Plan,
  StatusType,
  User,
  Video,
} from "./types";

export const fallbackError = (
  message: string,
  statusCode?: number
): APIErrorResponse => {
  return {
    isError: true,
    statusCode: statusCode || 500,
    errorCode: "ERR9999",
    errorMessage: message,
  };
};

export interface APIResponse {
  isError: boolean;
}

export interface APIErrorResponse extends APIResponse {
  statusCode: number;
  errorCode: string;
  errorMessage: string;
}

export interface EncryptedResponse extends APIResponse {
  response: string;
}

export interface GetAuthUserInfoResponse extends APIResponse {
  username: string;
  name: string;
  avatar: string;
  discordId: string;
}

export interface GetArenasResponse extends APIResponse {
  data: Arena[];
  count: number;
  page: number;
  pageSize: number;
  sortBy: string;
}

export interface GetVideosResponse extends APIResponse {
  data: Video[];
  count: number;
  page: number;
  pageSize: number;
  sortBy: string;
}

export interface GetUsersResponse extends APIResponse {
  data: User[];
  count: number;
  page: number;
  pageSize: number;
  sortBy: string;
}

export interface GetFieldsResponse extends APIResponse {
  data: Field[];
  count: number;
  page: number;
  pageSize: number;
  sortBy: string;
}

export interface GetPlansResponse extends APIResponse {
  data: Plan[];
  count: number;
  page: number;
  pageSize: number;
  sortBy: string;
}

export interface CreateArenaResponse extends Arena, APIResponse {
  userId: string;
  contactName: string;
  contactPhone: string;
}

export interface CreateFieldResponse extends Arena, APIResponse {}
export interface CreatePlanResponse extends Plan, APIResponse {}
export interface UpdateUserResponse extends User, APIResponse {
  phone: string;
  address: Address;
}

export interface UserPlanResponse extends Plan {
  bookingStartsAt: Date;
  bookingEndsAt: Date;
  bookingWeekday: Weekday;
  fieldId: string;
  status: StatusType;
}

export interface LoginResponse extends User, APIResponse {
  plans: UserPlanResponse[];
  actualVideoCount: number;
  hasDocument: boolean;
}

export interface LoginResponsePayload extends LoginResponse {
  actualPlan: UserPlanResponse;
  defaultPlan: UserPlanResponse;
  hasPlan: boolean;
}

export interface GetUserByIdResponse extends User, APIResponse {
  phone: string;
  address: Address;
  favoriteArenaId?: string;
}

export interface CreatePurchaseResponse extends APIResponse {
  externalReference: string;
  preferenceId: string;
  status: "pending" | "success";
  userId: string;
}

export interface GetUserVideoResponse extends APIResponse {
  name: string;
  arenaId: string;
  arenaName: string;
  fieldId: string;
  fieldDescription: string;
  videoId: string;
  uri: string;
  recordedAt: string;
}

export interface GetAdsResponse extends APIResponse {
  data: Ads[];
  count: number;
  page: number;
  pageSize: number;
  sortBy: string;
}

export interface GetAdByIdResponse extends Ads, APIResponse {}
export interface GetArenaByIdResponse extends Arena, APIResponse {}
export interface GetFiedlByIdResponse extends Field, APIResponse {}
export interface GetPlanByIdResponse extends Plan, APIResponse {}
export interface FinishPurchaseResponse extends APIResponse {
  status: "success" | "pending";
  invoiceUrl?: string;
  qrCode?: string;
  pixCode?: string;
}

export interface PaymentStatusResponse extends APIResponse {
  status: "success" | "pending";
}

export interface FinishPlanPurchaseResponse extends APIResponse {
  url: string;
}

export interface GetPurchasesVideosListResponse extends APIResponse {
  data: ListPurchasesVideosData[];
  count: number;
  page: number;
  pageSize: number;
  sortBy: string;
}

export interface GetPurchasesPlansListResponse extends APIResponse {
  data: ListPurchasesPlansData[];
  count: number;
  page: number;
  pageSize: number;
  sortBy: string;
}
